import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Heading } from '@components/Primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Link, Text } from '@primitives';
import { rem } from 'polished';

// @ts-ignore
import Logo from '@icons/logo_mobile.svg';

// @ts-ignore
import ArrowUp from '@icons/arrow_up.svg';
import { MenuToggle, MobileMenu } from '..';
import { UserAgreements } from '../UserAgreements';
import { useStores } from '@stores';
import { MessageBadge } from '@components/Messages';
import { ProfilePicture } from '@components/ProfilePicture';
import { PATHS } from '../../../constants/nav-items';

interface IMobileLayoutHeader {
  navItems: INavItem[];
  currentHref: string;
}

const BAR_HEIGHT = rem('70px');

const LogoutLinkContainer = styled(Box)`
  height: ${BAR_HEIGHT};
  display: flex;
  justify-content: center;
`;

const TopBar = styled(Box)`
  height: ${BAR_HEIGHT};
  position: relative;
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.333%;
  padding: 0 1rem;
 `;

const ProfilePicContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  z-index: 1;
`;

const StatusBar = styled(Box)`
  height: ${BAR_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LogoLink = styled(Link)`
  justify-content: center;
  > svg {
    height: 30px;

  }
`;

const TopBarRight = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MobileLayoutHeader: React.FC<IMobileLayoutHeader> = ({
  navItems, currentHref
}) => {
  const {
    agreementStore: { agreements },
    currentUserStore: { currentUser, profilePicUrl },
    messageStore: { unreadCount },
  }: {
    agreementStore: IAgreementStore;
    currentUserStore: ICurrentUserStore;
    messageStore: IMessageStore;
  } = useStores();

  const [menuOpen, setMenuOpen] = useState(false);

  const onMenuToggleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const renderLogout = () => (
    <LogoutLinkContainer color="mainBlue">
      <Link color="white" to={PATHS.auth.logout}>
        <Text fontSize="lg" fontWeight="300" mr="1">
          <M id="common.header.hero.logout" />
        </Text>
        <ArrowUp />
      </Link>
    </LogoutLinkContainer>
  );

  return (
    <Box forwardAs="header" onlyInMobile>
      <TopBar color="lightBlue">
        <div>
          <ProfilePicContainer>
            <ProfilePicture size={60} imageUrl={profilePicUrl} />
          </ProfilePicContainer>
        </div>
        <LogoLink to="/"><Logo /></LogoLink>
        <TopBarRight>
          <Link to={PATHS.messages.index}>
            <MessageBadge size={40} unreadCount={unreadCount} />
          </Link>
          <ToggleContainer>
            <MenuToggle onClick={onMenuToggleClick} isOpen={menuOpen} />
          </ToggleContainer>
        </TopBarRight>
      </TopBar>
      {agreements && agreements.length < 2 && (
        <StatusBar color="mainBlue">
          {!menuOpen && currentUser && (
            <Heading level="4" color="white">
              <M id="common.greeting" values={{ name: currentUser.first_name }} />
            </Heading>
          )}
          {menuOpen && renderLogout()}
        </StatusBar>
      )}
      <MobileMenu
        navItems={navItems}
        currentHref={currentHref}
        isOpen={menuOpen}
        onMenuToggleClick={onMenuToggleClick}
      />

      {!menuOpen && agreements && agreements.length > 1 && (
        <UserAgreements id="mobile-agreements-menu" agreements={agreements} />
      )}
      {menuOpen && agreements && agreements.length > 1 && renderLogout()}
    </Box>
  );
};

export { MobileLayoutHeader };
