import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getBasicStyles } from '../../styles/theme-utils';
import { Color, BasicStyles } from '../../types';

interface Props extends BasicStyles {
  color?: Color;
  type?: string;
  onClick: () => void;
  id: string;
  children: ReactNode;
}

const StyledButton = styled.button`
  border: none;
  background: none;
  ${(props) => getBasicStyles(props)};
  cursor: pointer;
  outline: none;
  font-size: ${(props) => props.theme.typography.fontSizes.sm};
`;

const InvisibleButton: React.FC<Props> = ({ type, ...rest }) => (
  // @ts-ignore
  <StyledButton type={type || 'button'} {...rest} />
);

export { InvisibleButton };
