import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import { Link, Heading } from '@primitives';
import { getPathWithoutLangPrefix } from '../../utils/intlUtils';

type NaviType = 'primary' | 'secondary';

interface Props {
  type?: NaviType;
  navItems: INavItem[];
}
interface NaviTextProps {
  type?: NaviType;
  active?: boolean;
}

interface ListItemProps {
  active: boolean;
  type?: NaviType;
}

const getNavBarStyles = (props: { theme: ITheme; type?: NaviType }) => {
  switch (props.type) {
    case 'secondary':
      return `
        background-color: ${props.theme.colors.white};
        height: ${rem('60px')};
        border-bottom: 1px solid ${props.theme.colors.mainBlue};
        h4 {
          font-size: ${props.theme.typography.fontSizes.sm};
        }
      `;
    case 'primary':
    default:
      return `
        background-color: ${props.theme.colors.mainBlue};
        height: ${rem('90px')};
      `;
  }
};

const getTextStyles = (props: NaviTextProps) => {
  switch (props.type) {
    case 'secondary':
      return `
      > a {
        font-weight: ${(props.active ? '400' : '300')};
        &:hover {
          text-decoration: ${(props.active ? 'none' : 'underline')};
        }
      }
      `;
    case 'primary':
    default:
      return `
        > a {
          color: #fff !important;
          font-weight: ${(props.active ? '500' : '300')};
          &:hover {
            text-decoration: ${(props.active ? 'none' : 'underline')};
          }
        }
      `;
  }
};

const NavBar = styled.nav<{ theme: ITheme; type?: NaviType }>`
  ${(props) => getNavBarStyles(props)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkList = styled.ul`
  display: flex;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li<ListItemProps>`
  padding: 0 1.5rem;
  height: 100%;

  a {
    text-align: center;
    display: flex;
    align-items: center;
    height: ${(props) => (props.type === 'secondary' ? 'calc(100% - 8px)' : '100%')};
    border-top: ${(props) => props.type === 'secondary' && '4px solid transparent'};
    border-bottom: ${(props) => props.type === 'secondary' && '4px solid'};
    border-bottom-color: ${(props) => (props.active && props.type === 'secondary' ? props.theme.colors.textBlue : 'transparent')};
  }

`;

const NavListHeading = styled(Heading)`
  height: 100%;
  ${(props: NaviTextProps) => getTextStyles(props)};
`;


const Navi: React.FC<Props> = ({ navItems, type }) => {
  const linkColor = (type === 'primary' ? 'white' : 'textBlue');

  const isActive = ({ href }: { href: string }) => {
    const { pathname } = useLocation();
    const pathWithoutLang = getPathWithoutLangPrefix(pathname)
    const partsCount = pathWithoutLang.split('/').length;
    const currentPrimary = pathWithoutLang.split('/')[1];
    const currentSecondary = pathWithoutLang.split('/')[2];

    if (type === 'primary') {
      if (pathWithoutLang === '/') return href === '/';
      return href.includes(currentPrimary);
    }

    return (pathWithoutLang === href) || (partsCount > 3 && href.includes(currentSecondary));
  };

  if (!navItems.length) return null;

  return (
    <NavBar type={type}>
      <LinkList>
        {navItems.map((item) => (
          <ListItem active={isActive(item)} type={type} key={item.name}>
            <NavListHeading level="4" active={isActive(item)} type={type}>
              <Link to={item.href} color={linkColor}>
                <M id={item.name} />
              </Link>
            </NavListHeading>
          </ListItem>
        ))}
      </LinkList>
    </NavBar>
  );
};

export { Navi };
