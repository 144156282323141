import { isEmail } from '../components/Form/formValidators';
export const LOGIN = [
  {
    required: true,
    id: 'email',
    label: 'username',
    placeholder: 'anna.asukas@esimerkki.com',
    validators: [isEmail],
  },
  {
    required: true,
    id: 'password',
    placeholder: '********',
    type: 'password',
  },
];

export const REGISTER = [{
  required: true,
  label: 'username',
  id: 'email',
  placeholder: 'anna.asukas@esimerkki.com',
  validators: [isEmail],
},
{
  required: true,
  id: 'password',
  placeholder: '********',
  type: 'password',
},
{
  required: true,
  id: 'passwordAgain',
  placeholder: '********',
  type: 'password',
}];
