import React from 'react';
import styled from 'styled-components';
import { getBasicStyles } from '../../styles/theme-utils';
import { FormattedMessage as M } from 'gatsby-plugin-intl';

interface Props extends BasicStyles {
  level: HeadingLevel;
  weight?: FontWeight;
  uppercase?: boolean;
  color?: Color;
  className?: string;
  center?: boolean;
  translate?: string;
}

const getFontSize = (level: Props['level'], theme: ITheme, mobile?: boolean) => {
  const sizeObj = mobile ? theme.typography.fontSizes.mobile : theme.typography.fontSizes;
  switch (level) {
    case '1':
    case '2':
      return sizeObj['xl'];
    case '3':
      return sizeObj['lg']
    case '4':
      return sizeObj['md'];
    case '5':
      return sizeObj['xs'];
    default:
      return sizeObj['sm'];
  }
};


const StyledHeading = styled.h1<Props>`
  font-size: ${(props) => (getFontSize(props.level, props.theme))};
  font-weight: ${(props) => (props.weight || '300')};
  text-transform: ${(props) => props.uppercase && 'uppercase'};
  ${(props) => getBasicStyles(props)};
  color: ${(props) => (props.color)};
`;

const Heading: React.FC<Props> = ({ translate, children, level, weight, ...rest }) => {
  const content = translate ? <M id={translate} /> : children;
  return (
    <StyledHeading
      // @ts-ignore
      as={`h${level || 1}`}
      level={level}
      weight={weight || '300'}
      {...rest}
    >
      {content}
    </StyledHeading>
  )
};

export { Heading };
