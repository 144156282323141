import React, { ReactNode } from 'react';
import { rem, transitions } from 'polished';
import styled from 'styled-components';
import { getBasicStyles } from '../../styles/theme-utils';
import { BasicStyles } from '../../types';
import theme from '@styles';

interface Props extends BasicStyles {
  children?: ReactNode;
}

const {
  colors, typography, boxShadow, transitions: t,
} = theme;

const StyledInput = styled.input`
  box-shadow: ${boxShadow.basic};
  -webkit-appearance: none;
  border: none;
  height: ${rem('45px')};
  font-weight: 300;
  font-size: ${typography.fontSizes.sm};
  ${(props: Props) => getBasicStyles(props)};
  padding: 0 ${rem('25px')};
  border-radius: 2px;
  outline: none;
  ${transitions(['box-shadow'], t.input)};

  &::placeholder {
    text-align: center;
    color: ${colors.placeholderGrey};
  }
  &:hover {
    box-shadow: ${boxShadow.inputHover};
  }
  &:focus {
    box-shadow: ${boxShadow.inputFocus};
  }
`;
const Input: React.FC<any> = ({ ...rest }) => (
  <StyledInput {...rest} />
);

export { Input };
