import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import BurgerOpen from '@icons/burger.svg';
// @ts-ignore
import BurgerClosed from '@icons/burger_closed.svg';
import { InvisibleButton } from '@primitives';
import { rem, transitions } from 'polished';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const Toggle = styled(InvisibleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem('70px')};
  width: ${rem('70px')};
  transform: ${(props: Props) => (props.isOpen && 'rotate(180deg) scaleX(-1)')};
  ${transitions('transform 0.3s ease-in 0s')}
  &:focus {
    outline: none;
  }
`;

const MenuToggle: React.FC<Props> = ({ isOpen, onClick }) => {
  const icon = (isOpen ? <BurgerClosed /> : <BurgerOpen />);
  return (
    <Toggle isOpen={isOpen} id="toggle-mobile-menu" onClick={onClick}>
      {icon}
    </Toggle>
  );
};

export { MenuToggle };
