import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.textBlue};
    font-weight: ${(props) => props.theme.typography.body.fontWeight};
    font-size: 18px;
  }
  html {
    font-family: ${(props) => props.theme.typography.body.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.sm};
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  h1, h2, h3, h4, h5 {
    font-family: ${(props) => props.theme.typography.heading.fontFamily};
    font-weight: ${(props) => props.theme.typography.heading.fontWeight};
    color: ${(props) => props.theme.colors.textBlue};
  }
  h1 {
    font-size: ${(props) => props.theme.typography.fontSizes.xl};
  }
  h2 {
    font-size: ${(props) => props.theme.typography.fontSizes.xl};
  }
  h3 {
    font-size: ${(props) => props.theme.typography.fontSizes.lg};
  }
  h4 {
    font-size: ${(props) => props.theme.typography.fontSizes.md};
  }
  h5 {
    font-size: ${(props) => props.theme.typography.fontSizes.sm};
  }
  p {
    line-height: 1.4em;
    font-size: ${(props) => props.theme.typography.fontSizes.sm};
  }
  ul {
    padding-left: 1.2rem;
  }

  ol li {
    margin-bottom: 5px;
  }

  a, a:hover,a:visited, a:active {
    color: ${(props) => props.theme.colors.textBlue};
  }

  @media screen and  (max-width: 769px) {
    body {
    font-size: 16px;
    }
  }
`;

export { GlobalStyles };
