/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Box } from '@primitives';
// @ts-ignore
import ShortcutMail from '@icons/shortcut_mail.svg';
// @ts-ignore
import ShortcutKey from '@icons/shortcut_key.svg';

// @ts-ignore
import ShortcutMaintenance from '@icons/shortcut_maintenance.svg';
// @ts-ignore
import { IconShortcutListItem } from '.';
import { PATHS } from '../../constants/nav-items';

const ShortcutList = styled.ul`
  list-style-type:none;
  padding: 0;
  margin: 0;
`;

interface IconShortcutList {
  extraShortcuts?: any;
}

const HERO_SHORTCUT_ITEMS = [{
  name: <M id="common.header.hero.shortcut.faultReport" />,
  info: <M id="common.header.hero.shortcut.faultReportInfo" />,
  href: PATHS.forms.faultReport,
  icon: <ShortcutMaintenance />,
}, {
  name: <M id="common.header.hero.shortcut.lostKeys" />,
  info: <M id="common.header.hero.shortcut.lostKeysInfo" />,
  href: PATHS.forms.lostKeys,
  icon: <ShortcutKey />,
}, {
  name: <M id="common.header.hero.shortcut.sendMessage" />,
  info: <M id="common.header.hero.shortcut.sendMessageInfo" />,
  href: PATHS.messages.index,
  icon: <ShortcutMail />,
}];


const IconShortcutList: FC<IconShortcutList> = ({ extraShortcuts, ...rest }) => {
  const items = extraShortcuts ? [...HERO_SHORTCUT_ITEMS, ...extraShortcuts] : HERO_SHORTCUT_ITEMS;

  return (
    <Box pt="2" {...rest}>
      <ShortcutList>
        {items.map((item) => (
          <li key={item.href}>
            <IconShortcutListItem item={item} />
          </li>
        ))}
      </ShortcutList>
    </Box>
  );
};

export { IconShortcutList };
