/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable react/no-danger */
import React, { FC, useState, useEffect } from 'react';
import { Text, InvisibleButton } from '@primitives';
import { IToast } from '../../types';
import styled from 'styled-components';
import { transitions, transparentize } from 'polished';
import { AiFillCheckCircle } from 'react-icons/ai';
import { IoMdCloseCircle } from 'react-icons/io';
import theme from '@styles';

const getBackground = (type: string, theme: any) => {
  switch (type) {
    case 'success':
      return theme.colors.toastGreen;
    case 'error':
      return theme.colors.toastRed;
    default:
      return theme.colors.white;
  }
};

interface IContainer {
  type: string;
  theme: any;
  visible: boolean;
  index: number;
  id: string;
}

interface Props extends IToast {
  index: number;
  onCloseClick: (index: number) => void;
}

const Container = styled.div`
  position: fixed;
  top: ${(props) => (props.index * 80) + 10}px;
  padding: 1rem 2rem;
  min-width: 200px;
  max-width: 400px;
  min-height: 40px;
  opacity: ${(props: IContainer) => (props.visible ? '1' : '0')};
  right: ${(props: IContainer) => (props.visible ? '1rem' : '-20rem')};
  background: ${(props: IContainer) => getBackground(props.type, props.theme)};
  border: 1px solid ${theme.colors.white};
  border-radius: 2px;
  ${transitions(['opacity', 'right', 'top'], '0.5s ease-in 0s')};
  box-shadow: ${theme.boxShadow.basic};
  align-items: center;
  display: flex;
  color: ${theme.colors.white};
  svg {
    height: 40px;
    width: 40px;
  }
`;

const CloseButton = styled(InvisibleButton)`
  margin-left: 2rem;
  margin-right: -1rem;
  cursor: pointer;
  svg {
    height: 2rem;
    color: ${transparentize(0.4, theme.colors.white)};
  }
`;

const Toast: FC<Props> = ({
  msg, type, index, onCloseClick,
}) => {
  const [visible, setVisible] = useState(false);

  const visibleTimeout = setTimeout(() => {
    if (!visible) setVisible(true);
  }, 100);

  useEffect(() => {
    return () => {
      setVisible(false);
      clearTimeout(visibleTimeout);
    };
  }, []);

  const onCloseButtonClick = () => {
    onCloseClick(index);
  };

  return (
    // @ts-ignore
    <Container index={index} visible={visible} type={type}>
      <AiFillCheckCircle />
      <Text ml="1" color="white">{msg}</Text>
      {type === 'error' && (
        <CloseButton onClick={onCloseButtonClick} id={`close-toast-${index}`}>
          <IoMdCloseCircle />
        </CloseButton>
      )}
    </Container>
  );
};

export { Toast };
