import React, { ReactNode, memo } from 'react';
import styled from 'styled-components';
import { Navi } from '../Navi';
import { Hero, MobileLayoutHeader } from '.';
import { getBasicStyles } from '../../styles/theme-utils';
import { NAV_ITEMS } from '../../constants/nav-items';
import { PAGE_NAV_ITEMS } from '@constants';

interface ILayoutHeader {
  currentHref: string;
  heroImage: any;
  heroContent: ReactNode;
  extraShortcuts?: any;
  afterHeader?: ReactNode;
}

const Header = styled.header`
  ${(props: { onlyInDesktop: boolean }) => getBasicStyles(props)};
`;

type PageNavItemKeys = keyof typeof PAGE_NAV_ITEMS;

const LayoutHeader: React.FC<ILayoutHeader> = ({
  currentHref, heroImage, heroContent, extraShortcuts, afterHeader,
}) => {
  // Find the primary nav item that directly matches `currentHref` first
  let currentNavItem = NAV_ITEMS.find((primaryItem: any) => primaryItem.href === currentHref);

  // If no direct match is found, try finding an item where `currentHref` matches one of its subPages
  if (!currentNavItem) {
    currentNavItem = NAV_ITEMS.find((primaryItem: any) => {
      return primaryItem.subPages && primaryItem.subPages.some((subPage: any) => currentHref.includes(subPage.href));
    });
  }

  // Extract subPages if `currentNavItem` has them
  let secondaryNavItems = currentNavItem ? currentNavItem.subPages : [];

  // Fallback to `PAGE_NAV_ITEMS` if no secondary items are found
  if (secondaryNavItems?.length === 0) {
    const currentPrimary = currentHref.split('/')[2] as keyof typeof PAGE_NAV_ITEMS;
    if (currentPrimary in PAGE_NAV_ITEMS) {
      secondaryNavItems = PAGE_NAV_ITEMS[currentPrimary];
    }
  }

  const MemoizedNavi = memo(Navi);
  const MemoizedHero = memo(Hero);


  return (
    <>
      <Header onlyInDesktop>
        <MemoizedHero
          heroImage={heroImage}
          heroContent={heroContent}
          extraShortcuts={extraShortcuts}
        />
        <MemoizedNavi type="primary" navItems={NAV_ITEMS} />
        {secondaryNavItems && secondaryNavItems.length > 0 && (
          <Navi
            type="secondary"
            navItems={secondaryNavItems}
          />
        )}
      </Header>
      <MobileLayoutHeader
        currentHref={currentHref}
        navItems={NAV_ITEMS}
      />
      {afterHeader}
    </>
  );
};


export { LayoutHeader };
