import React from 'react';
import styled from 'styled-components';
import { getBasicStyles } from '@styles';

interface Props extends BasicStyles {
  color?: Color;
  centerContent?: boolean;
  maxWidth?: string;
  minHeight?: string;
  onlyInDesktop?: boolean;
  onlyInMobile?: boolean;
  forwardAs?: string;
  ref?: any;
  className?: string;
  id?: string;
  style?: object;
}


const StyledDiv = styled.div`
  ${(props: Props) => getBasicStyles(props)};
  background-color: ${(props: Props) => (props.color ? props.theme.colors[props.color] : 'transparent')};
  text-align: ${(props: Props) => (props.centerContent && 'center')};
  max-width: ${(props: Props) => (props.maxWidth)};
  min-height: ${(props: Props) => props.minHeight || ''};
  box-shadow: ${(props: Props) => props.boxShadow && props.theme.boxShadow.basic};
`;

const Box: React.FC<Props> = ({ ...rest }) => (
  // @ts-ignore
  <StyledDiv {...rest} />
);

export { Box };
