
import React, { ReactNode } from 'react';
import { Layout } from '@components/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { TextSection } from '@components/TextSection';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  Box, Text, Heading, Link,
} from '@primitives';
import RehypeReact from 'rehype-react';
import { ILayout } from '../Layout';

interface IContentPageLayout extends ILayout {
  featuredImage?: any;
  featuredImageFile?: string;
  slug?: string;
  headerTexts?: {
    title: string;
    text?: string;
  };
  renderHeader?: Function;
  htmlContent?: any;
  wideContent?: boolean;
  replaceHref?: { id: string; value?: string }[];
}

const ImageContainer = styled(Box)`
  max-width: ${(props) => props.theme.constants.contentMaxWidth};
  margin-bottom: 2rem;
`;

const NarrowContent = styled(Box) <{ wideContent?: boolean }>`
  max-width: ${(props) => props.wideContent ? props.theme.constants.contentMaxWidth : props.theme.constants.narrowContentMaxWidth};
  margin: auto;
`;

const Header = styled(NarrowContent)`

`;

const List = styled.ul`
  line-height: 1.4em;
  li {
    margin-bottom: 0.5rem;
  }
`;

const Content = styled(NarrowContent)`
  a {
    display: inline;
  }
`;

type Html = {
  children: ReactNode;
  href?: string;
}

const ContentPageLayout: React.FC<IContentPageLayout> = ({
  headerTexts, featuredImage, htmlContent, children: kids, renderHeader, wideContent,
  replaceHref, featuredImageFile, slug, ...rest
}) => {
  const components = {
    p: ({ children }: Html) => <Text pb="1" as="p">{children}</Text>,
    h1: ({ children }: Html) => <Heading level="1" pt="1" pb="1">{children}</Heading>,
    h2: ({ children }: Html) => <Heading level="2" pt="1" pb="1">{children}</Heading>,
    h3: ({ children }: Html) => <Heading level="3" pt="1" pb="1">{children}</Heading>,
    ul: ({ children }: Html) => <List>{children}</List>,
    a: ({ href, children }: Html) => {
      let resolvedHref = href;
      if (replaceHref) {
        const replaceObj = replaceHref.find((r) => r.id === href)
        resolvedHref = replaceObj ? replaceObj.value : href;
      }
      return (
        <Link underline fontWeight="400" href={resolvedHref}>{children}</Link>
      );
    },
  };

  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components,
  }).Compiler;

  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "my_information_hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 936) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout heroImage={file} {...rest}>
      {(headerTexts || slug) && (
        <Header wideContent>
          <TextSection title={headerTexts?.title} text={headerTexts?.text} slug={slug} featuredImageFile={featuredImageFile} />
        </Header>
      )}
      {renderHeader && <Header>{renderHeader()}</Header>}
      {featuredImage && (
        <ImageContainer>
          <Img fluid={featuredImage.childImageSharp.fluid} />
        </ImageContainer>
      )}
      <Content wideContent={wideContent}>
        {htmlContent && renderAst(htmlContent)}
        {kids}
      </Content>
    </Layout>
  );
};

export { ContentPageLayout };
