import React, { ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, Button } from '@primitives';
import { getBasicStyles } from '../../styles/theme-utils';
import { rem } from 'polished';
// @ts-ignore
import Icon from '@icons/yh-logo-white.svg';

interface ILayoutEventHeader {
  afterHeader?: ReactNode;
}

interface IButton {
  name: string;
  background: string;
  color: string;
  size: string;
  locale: string
}

const Header = styled.header<{ onlyInDesktop: boolean }>`
  ${getBasicStyles};
  background-color: ${({ theme }) => theme.colors.mainBlue};
  height: ${rem(222)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;

  /* add styles for narrow screens */
  @media screen and (max-width: 768px) {
    position: static;
    margin-bottom: 1rem;
  }
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  /* add styles for narrow screens */
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const buttons: IButton[] = [
    {
      name: 'Suomeksi',
      background: 'white',
      color: 'black',
      size: 'small',
      locale: 'fi'
    },
    {
      name: 'In English',
      background: 'white',
      color: 'black',
      size: 'small',
      locale: 'en'
    }
  ];

const ButtonWrapper = styled.div<{ size: string }>`
  margin-right: ${rem(15)};
  button {
    font-size: ${({ size }) =>
      size === 'large' ? rem(20) : size === 'medium' ? rem(18) : rem(14)};
    padding: ${({ size }) =>
      size === 'large'
        ? `${rem(12)} ${rem(32)}`
        : size === 'medium'
        ? `${rem(10)} ${rem(28)}`
        : `${rem(8)} ${rem(24)}`};
    width: ${rem(115)};
    height: ${rem(35)};
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: ${rem(36)};
  line-height: 50px;

  @media (max-width: 768px) {
    font-size: ${rem(24)};
    line-height: 30px;
  }
`;

const Subtitle = styled.div`
  font-size: ${rem(18)};
  line-height: 25px;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: ${rem(14)};
    line-height: 20px;
  }
`;

const LayoutEventHeader: React.FC<ILayoutEventHeader> = ({ afterHeader }) => {

  const [lang, setLang] = useState<string>(
    localStorage.getItem('language') || 'fi'
  );

  const handleLangButtonClick = (locale: string) => {
    setLang(locale)
    window.location.reload();
  };

  useEffect(() => {
    localStorage.setItem('language', lang);
  }, [lang]);

  return (
    <>
      <Header onlyInDesktop={false}>
        <Link to="/">
          <Logo>
          <Icon />
          </Logo>
        </Link>
        <HeaderText>
            <Title>Ilmoittaudu asukaspäivään</Title>
            <Subtitle>Lisätietoa asukaspäivästä <a style={{color: 'white', textDecoration: 'none'}} href="https://www.yhkodit.fi/asukaspaiva/">yhkodit.fi -sivustolla</a></Subtitle>
        </HeaderText>
        <ButtonContainer>
          {buttons.map(({ name, background, color, size, locale }) => (
            <ButtonWrapper key={name} size={size}>
              <Button
                background={background}
                color={color}
                size={size}
                locale={locale}
                onClick={() => handleLangButtonClick(locale)}
              >
                {name}
              </Button>
            </ButtonWrapper>
          ))}
        </ButtonContainer>
      </Header>
      {afterHeader}
    </>
  );
};

export { LayoutEventHeader };
