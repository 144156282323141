/**
 * Get translation for given locale and entry
 * @param locale
 * @param entry
 * @param type
 * @returns
 */
export const getTranslation = (
  locale: string,
  entry: any,
  type: "title" | "content"
) => {
  if (entry[`${type}_${locale}`]) return entry[`${type}_${locale}`];

  // Fallback to legacy translation
  return entry[`${type}`];
};


/**
 * Get path without language prefix
 * @param pathname 
 * @returns 
 */
export const getPathWithoutLangPrefix = (pathname: string) => {
  const languages = ["fi", "sv", "en"];
  const hasLangPrefix = languages.includes(pathname.split("/")[1])

  if (hasLangPrefix) {
    const segmentsWithoutLangPrefix = pathname.split("/").slice(2);
    return `/${segmentsWithoutLangPrefix.join("/")}`;
  }

  return pathname;
};