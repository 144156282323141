import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Text } from '@primitives';
import { BasicStyles, FontWeight } from '../../types';

interface Props extends BasicStyles {
  children: ReactNode;
  id: string;
  fontWeight?: FontWeight;
}

const StyledSpan = styled(Text)`
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  width: 100%;
  height: 1rem;
`;

const ReadonlyValue: React.FC<Props> = ({ id, ...rest }) => (
  <StyledSpan
    // @ts-ignore
    id={id}
    pb="0.5"
    color="black"
    {...rest}
  />
);

export { ReadonlyValue };
