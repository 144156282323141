import React, { ReactNode, useEffect } from "react";
import { Heading, Text, Box } from "@primitives";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useStores } from "../../stores/index";
import { useIntl } from "gatsby-plugin-intl";

export interface TextSectionProps {
  title?: string | ReactNode;
  text?: string | ReactNode;
  level?: HeadingLevel;
  alignment?: "left" | "right" | "center";
  afterTitle?: string | ReactNode;
  slug?: string;
  featuredImageFile?: string;
}

const getAlignment = (props: any) => {
  if (props.alignment) return props.alignment;
  return props.level === "2" ? "center" : "left";
};

const Container = styled(Box)`
  text-align: ${(props) => getAlignment(props)};
  margin-bottom: ${(props: TextSectionProps) =>
    props.level === "2" ? "3rem" : "0rem"};
`;

const PageContent = styled(Box)`
  max-width: ${(props) => props.theme.constants.narrowContentMaxWidth};
  text-align: left;
  margin: auto;
`;

const ImageContainer = styled(Box)`
  width: 100%;
  max-width: ${(props) => props.theme.constants.contentMaxWidth};
  img {
    max-width: 100%;
  }
`;

let initializedFor = {} as any;

const TextSection: React.FC<TextSectionProps> = observer(
  ({ title, text, level, alignment, afterTitle, slug, featuredImageFile }) => {
    const { locale } = useIntl();
    const {
      pageStore: { getPage, page },
    } = useStores();
    const headingLevel = level || "2";

    useEffect(() => {
      if (locale && slug) {
        getPage(slug, locale);
      }
      if (!slug) return;
      if (initializedFor[slug]) return;
      initializedFor[slug] = true;
    }, [slug, locale]);

    const resolvedTitle =
      (slug && page[slug]?.[locale || "fi"]?.title) || title;

    return (
      <Container level={headingLevel} forwardAs="section" alignment={alignment}>
        {resolvedTitle && (
          <Heading mb="2" level={headingLevel}>
            {resolvedTitle}
          </Heading>
        )}
        {text && <Text>{text}</Text>}
        {slug && page[slug]?.[locale || "fi"]?.ingress && (
          <div
            dangerouslySetInnerHTML={{
              __html: page[slug]?.[locale || "fi"]?.ingress,
            }}
          />
        )}
        {afterTitle && <Box mt="2">{afterTitle}</Box>}
        {featuredImageFile && (
          <ImageContainer mt="2">
            <img src={featuredImageFile} />
          </ImageContainer>
        )}
        {slug && page[slug]?.[locale || "fi"]?.content && (
          <PageContent mt="2">
            <div
              dangerouslySetInnerHTML={{
                __html: page[slug]?.[locale || "fi"]?.content,
              }}
            />
          </PageContent>
        )}
      </Container>
    );
  }
);

export { TextSection };
