import React from 'react';
import styled from 'styled-components';
import { getBasicStyles } from '../../styles/theme-utils';
import { Color, FontWeight, BasicStyles } from '../../types';

interface Props extends BasicStyles {
  color?: Color;
  fontWeight?: FontWeight;
  htmlFor?: string;
  as?: string;
  centerLabels?: boolean;
  className?: string;
  fontSize?: string;
}

const StyledLabel = styled.label`
  font-size: ${(props: Props) => (props.theme.typography.fontSizes[props.fontSize || 'sm'])};
  font-weight: ${(props: Props) => props.fontWeight || '500'};
  color: ${(props: Props) => (props.theme.colors[props.color || 'black'])};
  ${(props: Props) => getBasicStyles(props)};
  display: block;
`;

const Label: React.FC<Props> = ({ ...rest }) => (
  // @ts-ignore
  <StyledLabel {...rest} />
);

export { Label };
