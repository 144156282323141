import { rem } from 'polished';
import { DefaultTheme } from 'styled-components';

export const fontSizes = {
  xl: '1.67em', // h1, h2
  lg: '1.3em', // h3
  md: '1.11em', // h4, navi, list heading, page excerpt
  sm: '1em', // h5, uppercase text/link, body text, button, label..
  xs: '0.75em', // date / meta text / small uppercase link
};

export const colors = {
  black: '#222222',
  blackestBlack: '#000',
  cyan: '#00CBCD',
  darkGrey: '#303C4B',
  errorRed: '#d72700',
  rose: '#FFC3D9',
  messageRed: '#9A0037',
  disabledGrey: '#707070',
  placeholderGrey: '#878787',
  greyBorder: '#DADADA',
  grey: '#9C9C9C',
  contentGrey: '#F8F8F8',
  lightBlue: '#F0F9FF',
  lightGrey: '#F6F6F6',
  mainBlue: '#0070BA',
  statusGreen: '#09DB76',
  statusGrey: '#A5A5A5',
  statusOrange: '#FF881A',
  statusPurple: '#C734B8',
  textBlue: '#07234B',
  focusBlue: '#0D3774',
  white: '#ffffff',
  toastGreen: '#4BB543',
  toastRed: '#da2b2b',
  lightGreen: '#EFFFF9',
  teal: '#00CBCD',
  statusYellow: '#F9FC67',
  lightOrange: '#FFB067',
};

export const screens = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const themeObject: DefaultTheme = {
  typography: {
    fontSizes,
    body: {
      fontFamily: "'Roboto', 'Helvetica Neue', Helvetica, sans-serif",
      fontWeight: 300,
    },
    heading: {
      fontFamily: "'Roboto', 'Helvetica Neue', Helvetica, sans-serif",
      fontWeight: 300,
    },
    navBar: {

    },
  },
  boxShadow: {
    basic: '0px 1px 6px rgba(0, 0, 0, 0.25)',
    inputHover: '0px 2px 7px rgba(0, 0, 0, 0.6)',
    inputFocus: '0px 2px 7px #A8CFEA',
  },
  colors,
  screens,
  constants: {
    siteMaxWidth: rem('1280px'),
    contentMaxWidth: rem('1024px'),
    narrowContentMaxWidth: rem('700px'),
  },
  transitions: {
    input: '0.15s ease-in 0.0s',
  },
};

export { themeObject };
