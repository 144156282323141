import { Box, Heading, Link, Text } from '@components/Primitives';
import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import imgSrc from '../../resources/images/checklist_item.png';
import { IChecklistItem } from '../../types/ChecklistItem';
import { until } from '../../styles/theme-utils';

export interface ResidentChecklistItemProps {
  item: IChecklistItem;
}

const Item = styled(Box)`
  display: flex;
  min-width: 400px;
  min-height: 180px;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  ${until('md', `{
    min-width: 300px;
  }`
  )};
`;

const Main = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const Image = styled.img`
  margin-left: 1rem;
`;
const ResidentChecklistItem: React.FC<ResidentChecklistItemProps> = ({
  item: { title, content, href, linkText },
}) => {
  return (
    <Item key={title} color="lightBlue" p="1" className="carousel-item">
      <Main>
        <Box>
          <Heading weight="400" level="4" mb="1">
            {title}
          </Heading>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Box>
        <Image src={imgSrc} role="presentation" />
      </Main>
      <Text uppercase>
        <Link fontWeight="400" href={href}>
          {linkText}
        </Link>
      </Text>
    </Item>
  );
};

export { ResidentChecklistItem };
