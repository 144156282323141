import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Heading, Text, Link } from '../Primitives';

interface IconShortcutListItem {
  item: {
    href: string;
    icon: ReactNode;
    name: ReactNode;
    info: ReactNode;
  };
}

const ItemContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  text-decoration: none;
`;

const TextContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const IconContainer = styled.span`
  background: ${(props) => props.theme.colors.mainBlue};
  height: ${rem('58px')};
  min-width: ${rem('64px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;


const IconShortcutListItem: React.FC<IconShortcutListItem> = ({
  item: {
    name, icon, info, href,
  },
}) => (
    <ItemContainer to={href}>
      <IconContainer>{icon}</IconContainer>
      <TextContainer>
        <Heading level="4" mb="0.25">{name}</Heading>
        <Text fontSize="sm">{info}</Text>
      </TextContainer>
    </ItemContainer>
  );

export { IconShortcutListItem };
