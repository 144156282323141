import React from 'react';
import styled from 'styled-components';
import { Text } from '@primitives';
import {
  FormattedDate as FDate, FormattedTime as Time, FormattedMessage as M,
} from 'gatsby-plugin-intl';

export interface IMessageDate {
  date: string;
  thread?: boolean;
}

const DateStr = styled(Text)`
  > span { margin-right: 0.25rem }
`;

const MessageDate: React.FC<IMessageDate> = ({
  date, thread,
}) => {
  // Propably not the best way to do this, but..
  let d = `${date}Z`;

  return (
    <DateStr fontSize="xs">
      {thread && <span><M id="pages.messages.previousMessage" /></span>}
      <span><FDate value={d} /></span>
      <span><M id="pages.messages.clock" /></span>
      <span><Time value={d} /></span>
    </DateStr>
  );
};


export { MessageDate };
