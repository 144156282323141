import React, { ReactNode } from 'react';
import { Box } from '@primitives';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-background-image';

// @ts-ignore
import Logo from '@icons/logo_mobile.svg';
import { until } from '../../styles/theme-utils';
import { observer } from 'mobx-react';

interface Props {
  children?: ReactNode;
}

const Sidebar = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 500px;
  ${until('md', `
    min-width: 0;
  `)}
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: calc(100vw - 500px) 500px;
  height: 100vh;
  ${until('lg', `
    grid-template-columns: 50% 50%;
  `)}
  ${until('md', `
    display: block;
    height: auto;
  `)}
`;

const Hero = styled.div`
  height: 100%;
  > div {
    height: 100%;
  }
  ${until('md', `
    display: none;
  `)}
`;

const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  form {
    width: 100%;
  }
`;

const TwoColumnLayout: React.FC<Props> = observer(({ children }) => {
  const { loginBg, loginImg } = useStaticQuery(graphql`
    query {
      loginBg: file(relativePath: { eq: "login_bg.png" }) {
        childImageSharp {
          fluid(maxHeight: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      loginImg: file(relativePath: {eq: "login_img.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Hero>
        <Img
          Tag="div"
          fluid={loginBg.childImageSharp.fluid}
        />
      </Hero>
      <Sidebar color="lightBlue">
        <LoginContainer>
          <Box>
            <Logo />
          </Box>
          <Box mb="2">
            <Img
              fixed={loginImg.childImageSharp.fixed}
              alt=""
            />
          </Box>
          {children}
        </LoginContainer>
      </Sidebar>
    </Container>
  );
});

export { TwoColumnLayout };
