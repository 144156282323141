import React from 'react';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { getBasicStyles } from '../../styles/';
interface Props extends BasicStyles {
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  uppercase?: boolean;
  color?: Color;
  underline?: boolean;
  id?: string;
  className?: string;
  as?: string;
  style?: object;
  translate?: string;
}


const StyledText = styled.p<Props>`
  font-size: ${(props) => props.theme.typography.fontSizes[props.fontSize || 'sm']};
  font-weight: ${(props) => props.fontWeight || '300'};
  text-transform: ${(props) => props.uppercase && 'uppercase'};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  color: ${(props) => props.color && props.theme.colors[props.color]};
  font-family: ${(props) => props.theme.typography.body.fontFamily} !important;
  line-height: 1.4em;
  display: block;
  ${(props) => getBasicStyles(props)};
`;

const Text: React.FC<Props> = ({ children, translate, ...rest }) => {
  const content = translate ? <M id={translate} /> : children;

  return (
    // @ts-ignore
    <StyledText {...rest}>{content}</StyledText>
  )
}

export { Text };

