import { Box, Heading, Link, Spinner, Text } from "@components/Primitives";
import { FormattedMessage as M, useIntl } from "gatsby-plugin-intl";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { IChecklistStore } from "src/stores/ChecklistStore";
import styled from "styled-components";
import { PATHS } from "../../constants/nav-items";
import { useStores } from "../../stores/index";
import { ResidentChecklistItem } from "./ResidentChecklistItem";

export interface ResidentChecklistProps {}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ItemContainer = styled(Box)`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  max-width: 1350px;
  padding: 1rem;
  > div:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ResidentChecklist: React.FC<ResidentChecklistProps> = observer(() => {
  const { locale } = useIntl();

  const {
    agreementStore: { selectedAgreement },
    checklistStore: { getChecklist, checklist, state },
  }: {
    agreementStore: IAgreementStore;
    checklistStore: IChecklistStore;
  } = useStores();

  useEffect(() => {
    if (selectedAgreement && locale) {
      getChecklist(locale);
    }
  }, [selectedAgreement, locale]);

  return (
    <Wrapper mt="3" p="1">
      <Heading mb="2" level="2">
        <M id="pages.indexPage.checklist.title" />
      </Heading>
      <Text fontSize="md">
        <M id="pages.indexPage.checklist.body" />
      </Text>
      <Text mt="2" mb="2" uppercase>
        <Link fontWeight="400" to={PATHS.living.checklist}>
          <M id="pages.indexPage.checklist.linkText" />
        </Link>
      </Text>
      <ItemContainer>
        {state === "Loading" && <Spinner color="mainBlue" />}
        {checklist.map &&
          checklist?.map((item: IChecklistItem, _i: number) => (
            <ResidentChecklistItem item={item} />
          ))}
      </ItemContainer>
    </Wrapper>
  );
});

export { ResidentChecklist };
