import React, {
  useState, useLayoutEffect, useEffect, Ref,
} from 'react';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Link } from '@primitives';
import { rem } from 'polished';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { MenuItemToggle } from '..';
import { INavItem } from '../../../types';

interface Props {
  isOpen: boolean;
  navItems: INavItem[];
  currentHref: string;
  onMenuToggleClick: () => void;
}
const List = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
interface Menu {
  isOpen: boolean;
  ref?: Ref<HTMLDivElement> | null;
}

const MobileMenuContainer = styled.div<Menu>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: absolute;
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  left: 0;
  top: ${rem('140px')};
  bottom: 0;
  z-index: 1;
  overflow: auto;
`;

const ListItem = styled.li`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.mainBlue};
`;

const SubList = styled(List) <Menu>`
  height: ${(props) => (props.isOpen ? 'auto' : 0)};
  overflow: hidden;
  padding-bottom: ${(props) => (props.isOpen ? '1rem' : 0)};
`;

const SubListItem = styled.li`
  font-size: ${(props) => props.theme.typography.fontSizes.sm};

  > a {
    min-height: ${rem('40px')};
    padding: 0 2rem;
    height: 100%;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    &[aria-current=page] {
      color: ${(props) => props.theme.colors.mainBlue};
      font-weight: 400;
      cursor: default;
    }
  }
`;

const MobileMenu: React.FC<Props> = ({
  navItems, isOpen, currentHref, onMenuToggleClick,
}) => {
  const currentItem = navItems.find((item) => item.href.includes(currentHref));
  const currentName = currentItem ? currentItem.name : '';
  const [openItem, setOpenItem] = useState(currentName);
  const menu = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (openItem !== currentName) setOpenItem(currentName);
  }, [currentName]);

  useLayoutEffect(() => {
    if (isOpen) {
     // console.log(menu)
      // @ts-ignore
      disableBodyScroll();
    } else {
      // @ts-ignore
      enableBodyScroll();
    }
  }, [isOpen]);

  const onToggleClick = (item: INavItem) => {
    if (openItem === item.name) {
      setOpenItem('');
      if (!item.subPages) onMenuToggleClick();
      return;
    }
    setOpenItem(item.name);
  };

  return (
    <MobileMenuContainer isOpen={isOpen} ref={menu}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name}>
            <MenuItemToggle
              onToggleClick={onToggleClick}
              item={item}
              isOpen={item.name === openItem}
            />
            {item.subPages
              && (
                <SubList isOpen={openItem === item.name}>
                  {item.subPages.map((subItem: INavItem) => (
                    <SubListItem key={subItem.name}>
                      <Link to={subItem.href}>
                        <M id={subItem.name} />
                      </Link>
                    </SubListItem>
                  ))}
                </SubList>
              )}
          </ListItem>
        ))}
      </List>
    </MobileMenuContainer>
  );
};

export { MobileMenu };
