import React, { ReactNode } from 'react';
import { Box, InvisibleButton } from '@primitives';
import styled from 'styled-components';

// @ts-ignore
import Rate1 from '@icons/feedback_form_rate_1.svg';
// @ts-ignore
import Rate2 from '@icons/feedback_form_rate_2.svg';
// @ts-ignore
import Rate3 from '@icons/feedback_form_rate_3.svg';
// @ts-ignore
import Rate4 from '@icons/feedback_form_rate_4.svg';
import { IFeedback } from '../../types';

interface IFeedbackFormRating {
  onRateClick: (id: IFeedback['rating']) => void;
  value: IFeedback['rating'];
}

interface ISmileyButton {
  button: {
    id: string;
    icon: ReactNode;
  };
  active: boolean;
  onRateClick: (id: IFeedback['rating']) => void;
}

const Smiley = styled(InvisibleButton)`
  border: 2px solid transparent;
  padding: 2px;
  height: 54px;
  width: 54px;
  border-radius: 45px;
  margin: 0 5px;
  svg {
    height: 46px;
    width: 46px;
  }
  ${({ active }: { active: boolean }) => active && `
    border-color: #fff;
  `};
  &:hover {
    border-color: #fff;
  }
`;

const SmileyButton: React.FC<ISmileyButton> = ({ onRateClick, button, active }) => {
  const onClick = () => {
    onRateClick(button.id as IFeedback['rating']);
  };

  return (
    <Smiley active={active} id={button.id} onClick={onClick} aria-label={button.id} key={button.id}>
      {button.icon}
    </Smiley>
  );
};

const FeedbackFormRating: React.FC<IFeedbackFormRating> = ({
  onRateClick, value,
}) => {
  const onClick = (id: IFeedback['rating']) => {
    onRateClick(id);
  };

  const buttons = [{
    id: '1',
    icon: <Rate1 />,
  }, {
    id: '2',
    icon: <Rate2 />,
  }, {
    id: '3',
    icon: <Rate3 />,
  }, {
    id: '4',
    icon: <Rate4 />,
  }];

  return (
    <Box pb="1">
      {buttons.map((button) => (
        <SmileyButton
          key={button.id}
          onRateClick={onClick}
          button={button}
          active={value === button.id}
        />
      ))}
    </Box>
  );
};

export { FeedbackFormRating };
