import { useState, useEffect } from 'react';

export * from './myInformationUtils';
export * from './commonUtils';

/**
 * Checks if component is rendered by client / server
 */
export const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? 'client' : 'server';

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};
