import { getPushSubscriptionInfo, addPushSubscriptionInfo, testPush, PushNotificationType } from './api';
import { ToastStore } from '@stores';
// @ts-ignore
import urlBase64ToUint8Array from 'urlb64touint8array';

class PushModel {
  permissionAsked = false;

  askPermission = async () => {
    if (this.permissionAsked) return;

    this.permissionAsked = true;

    const promise = new Promise((resolve: any, reject: any) => {
      const permissionResult = Notification.requestPermission((result) => {
        return resolve(result);
      });

      if (permissionResult) {
        return permissionResult.then(resolve, reject);
      }
    })

    const permission = await promise;

    navigator.serviceWorker.ready.then(async (serviceWorkerRegistration) => {
      const existingSubscription = await serviceWorkerRegistration.pushManager.getSubscription();
      if (existingSubscription) return;

      if (permission === 'granted') {
        this.subscribeUser(serviceWorkerRegistration);
      } else {
        this.unsubscribeUser(serviceWorkerRegistration);
      }
    });
  }

  subscribeUser = async (serviceWorkerRegistration: ServiceWorkerRegistration) => {
    const subscriptionInfo = await getPushSubscriptionInfo() as any;

    // TODO check this
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(subscriptionInfo.data.public_key),
    }
    const subscription = await serviceWorkerRegistration.pushManager.subscribe(subscribeOptions);
    addPushSubscriptionInfo({ subscription_info: subscription });
  };

  /**
   * Unsubscribes user e.g. when we don't have permission to show push notifications
   */
  unsubscribeUser = (serviceWorkerRegistration: ServiceWorkerRegistration) => {
    serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {
      if (!subscription) return;
      subscription.unsubscribe().then(function (successful) {
        console.info('Unsubscribed', successful);
      }).catch((e: any) => {
        console.error('Unsubscription failed', e);
      })
    });
  }

  addPushSubscriptionInfo = async (data: any) => {
    try {
      return addPushSubscriptionInfo(data);
    } catch (error) {
      ToastStore.showError('common.errors.pushNotification', true);
      return Promise.reject();
    }
  }

  getPushSubscriptionInfo = async () => {
    try {
      return getPushSubscriptionInfo();
    } catch (error) {
      ToastStore.showError('common.errors.pushNotification', true);
      return Promise.reject();
    }
  }

  testPush = async (userId: string, type: PushNotificationType) => {
    return testPush(userId, type);
  };
};

const PushService = new PushModel();

export default PushService;
