
import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, FormattedMessage as M } from 'gatsby-plugin-intl';
import { rem } from 'polished';
import { until } from '../../styles/theme-utils';

type NavItem = {
  href: string;
  name: string;
};

interface NaviButtonsProps {
  items: NavItem[];
}

const Container = styled.nav`
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 2rem;
`;

const ButtonList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
 ${until('md', `
    flex-wrap: wrap;
    justify-content: center;
 `)};
`;

const ListItem = styled.li`
  margin: 0 0.5rem;
  ${until('md', `
    margin: 0.5rem;
 `)};
`;

const Button = styled(GatsbyLink)`
  background-color: ${({ theme: { colors } }) => colors.textBlue};
  color: ${({ theme: { colors } }) => `${colors.white} !important`};
  padding: 0 2rem;
  height: ${rem('45px')};
  display: flex !important;
  align-items: center;
  text-decoration: none;
`;

const NaviButtons: React.FC<NaviButtonsProps> = ({
  items,
}) => (
  <Container>
    <ButtonList>
      {items && items.map((item: NavItem) => (
        <ListItem key={item.name}>
          <Button to={item.href}>
            <M id={item.name} />
          </Button>
        </ListItem>
      ))}
    </ButtonList>
  </Container>
);


export { NaviButtons };
