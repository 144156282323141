
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

interface ProfilePictureProps {
  imageUrl?: string;
  size: number;
}

const Circle = styled.div<ProfilePictureProps>`
  box-shadow: ${({ theme: { boxShadow } }) => boxShadow.basic};
  border-radius: ${(props => rem(props.size))};
  height: ${(props => rem(props.size))};
  width: ${(props => rem(props.size))};
  max-height: ${(props => rem(props.size))};
  background: ${(props) => props.theme.colors.mainBlue};
  display: flex;
  img {
    border-radius: ${(props => rem(props.size))};
    height: ${(props => rem(props.size))};
    width: ${(props => rem(props.size))};
  }
`;

const ProfilePicture: React.FC<ProfilePictureProps> = ({ imageUrl, ...rest }) => {
  return (
    <Circle {...rest}>
      {imageUrl && <img src={imageUrl} alt="Profiilikuva" />}
    </Circle>
  );
};

export { ProfilePicture };
