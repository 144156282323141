import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Box } from './Box';

interface Props {
  dangerouslySetInnerHTML?: any;
  forwardAs?: string;
}

const StyledBox = styled(Box)`
  margin: 0 auto;
  display: flex;
    justify-content: center;
    align-items: center;
`;

const TextContent: React.FC<Props> = ({ ...rest }) => (
  <StyledBox pt="3" pb="3" maxWidth={rem('700px')} {...rest} />
);

export { TextContent };
