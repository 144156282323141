import { isValidSSN } from '../components/Form/formValidators';

const notificationOptions = [
  { id: 'messages', label: 'allowMessages' },
  { id: 'faultreports', label: 'allowFaultReports' },
  { id: 'exceptions', label: 'allowExceptions' },
  { id: 'notifications', label: 'allowNotifications' },

];
export const DWELLING = {
  general: [
    { id: 'dwelling.number', label: 'dwellingNo' },
    { id: 'dwelling.dwelling_type', label: 'dwellingType' },
    { id: 'dwelling.area', label: 'area' },
    { id: 'dwelling.floor', label: 'floor' },
    { id: 'dwelling.loaned_keys', label: 'keyCount' },
    { id: 'parkingLots', label: 'ownParkingLots', type: 'customRender' }
  ],
  cableInternet: [
    { id: 'dwelling.internet_provider.name', label: 'internetProvider' },
    { id: 'dwelling.internet_provider.network_adapter_type', label: 'internetType' },
    { id: 'dwelling.internet_provider.order_url', label: 'internetOrders', parser: 'link' },
    { id: 'dwelling.cable_tv_provider.name', label: 'cableProvider' },
    { id: 'dwelling.cable_tv_provider.order_url', label: 'cableOrders' },
  ],
  estate: [
    { label: 'address', id: 'estate.address' },
    { label: 'district', id: 'estate.district' },
    { id: 'building.building_type', label: 'buildingType' },
    { id: 'building.floors', label: 'floorCount' },
    { id: 'building.built_year', label: 'buildYear' },
    { id: 'estate.dwellings_num', label: 'dwellingCount' },
    { id: 'estate.parking_places_num', label: 'parkingSpaceCount' },
    { id: 'estate.tv_distribution_system', label: 'tv' },
    { id: 'owner.name', label: 'owner' },
    { id: 'estate.storage', label: 'storageRoom', parser: 'boolean' },
    { id: 'estate.common_areas', label: 'commonSpace' },
    { id: 'building.heating_system', label: 'heating' },
    { id: 'building.ventilation_system', label: 'ventilation' },
    { id: 'building.energy_efficiency_class', label: 'energyClass' },
  ],
};

export const PAYMENTS = [
  { id: 'owner.account_bic', label: 'bic' },
  { id: 'owner.account_iban', label: 'iban' },
  { id: 'reference_number', label: 'referenceNumber' },
  { id: 'virtual_ean', label: 'virtualEan' },
];

export const MY_INFORMATION = {
  general: [
    { id: 'phone_number', label: 'phoneNumber', required: true },
    {
      id: 'language',
      type: 'radioButtonGroup',
      options: [{ id: 'finnish' }, { id: 'swedish' }, { id: 'english' }],
    },
    { id: 'email', label: 'email', type: 'readOnly' },

    {
      id: 'profilePic',
      label: 'uploadYourPic',
      type: 'customRender',
    },
  ],
  personalInformation: [
    { id: 'first_name', label: 'firstNames' },
    { id: 'last_name', label: 'lastName' },
    { id: 'address', label: 'streetAddress' },
    { id: 'postal_number', label: 'postalCode' },
    { id: 'postal_office', label: 'city' },
    { id: 'birth_date', label: 'dateOfBirth', parser: 'date' },
    { id: 'residence_time', label: 'residenceTime' },
    { id: 'personal_data_protection', label: 'denialOfPersonalData', parser: 'boolean' },
    { id: 'payee', parser: 'boolean' },
    { id: 'resident', parser: 'boolean' },
  ],
  notifications: [
    {
      id: 'notifications.sms',
      type: 'checkboxGroup',
      label: 'allowSms',
      options: notificationOptions,
    }, {
      id: 'notifications.email',
      type: 'checkboxGroup',
      label: 'allowEmails',
      options: notificationOptions,
    }, {
      id: 'notifications.push',
      type: 'checkboxGroup',
      label: 'allowPushNotifications',
      options: notificationOptions,
    }, {
      id: 'newsletter',
      type: 'customRender'
    }
  ],
};


export const RESIDENTS = {
  otherAgreementParty: [
    { id: 'first_name', label: 'firstNames' },
    { id: 'last_name', label: 'lastName' },
    { id: 'birth_date', label: 'dateOfBirth', parser: 'date' },
    { id: 'residence_time', label: 'residenceTime' },
  ],
  otherResidents: [
    { id: 'first_name', label: 'firstNames', required: true },
    { id: 'last_name', label: 'lastName', required: true },
    { id: 'birth_date', label: 'dateOfBirth', type: 'readOnly', parser: 'date' },
    { id: 'residence_time', label: 'residenceTime', type: 'readOnly' },
  ],
  otherResidentsReadonly: [
    { id: 'first_name', label: 'firstNames', type: 'readOnly', required: true },
    { id: 'last_name', label: 'lastName', type: 'readOnly', required: true },
    { id: 'birth_date', label: 'dateOfBirth', type: 'readOnly', parser: 'date' },
    { id: 'residence_time', label: 'residenceTime', type: 'readOnly' },
  ],
  newResident: [
    { id: 'first_name', label: 'firstNames', required: true },
    { id: 'last_name', label: 'lastName', required: true },
    { id: 'ssn', label: 'socialSecurityNumber', required: true, validators: [isValidSSN] },
  ],
};

export const PARKING_LOT = [
  { id: 'number', label: 'parkingLotNumber' },
  { id: 'parkinglot_type', label: 'parkingLotType' },
  { id: 'parkingLotTimespan', label: 'parkingLotTimespan', type: 'customRender' },
];

export const AGREEMENT = {
  general: [
    { id: 'start_date', label: 'startDate', parser: 'date' },
    { id: 'end_date', label: 'endDate', parser: 'date' },
    { id: 'sign_date', label: 'agreementSigned', parser: 'date' },
    { id: 'transferred_to_debt_collection', label: 'transferredToDebtCollection', parser: 'boolean' },
    { id: 'termination_date', label: 'terminationDate', parser: 'date' },
    { id: 'reference_number', label: 'agreementReferenceNumber' },
    { id: 'due_day', label: 'dueDate' },
    { id: 'owner_name', label: 'owner' },
    { id: 'owner_business_id', label: 'businessId' },
    { id: 'owner_address', label: 'ownerAddress' },
  ],
  parties: {
    primary: [
      { id: 'first_name', label: 'firstNames' },
      { id: 'last_name', label: 'lastName' },
      { id: 'address', Label: 'streetAddress' },
      { id: 'postal_office', label: 'city' },
      { id: 'birth_date', label: 'dateOfBirth', parser: 'date' },
      { id: 'payee', parser: 'boolean' },
      { id: 'resident', parser: 'boolean' },
      { id: 'phone_number', label: 'phoneNumber' },
      { id: 'email' },
      { id: 'residence_time', label: 'residenceTime' },
      { id: 'personal_data_protection', label: 'denialOfPersonalData', parser: 'boolean' },
      { id: 'liability_end_date', label: 'paymentLiabilityEndDate', parser: 'date' },
    ],
    secondary: [
      { id: 'first_name', label: 'firstNames' },
      { id: 'last_name', label: 'lastName' },
      { id: 'birth_date', label: 'dateOfBirth', parser: 'date' },
      { id: 'payee', parser: 'boolean' },
      { id: 'resident', parser: 'boolean' },
      { id: 'residence_time', label: 'residenceTime' },
      { id: 'personal_data_protection', label: 'denialOfPersonalData', parser: 'boolean' },
      { id: 'liability_end_date', label: 'paymentLiabilityEndDate', parser: 'date' },
    ],
  },
};
