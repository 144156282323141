import React, { useState } from 'react';
import {
  Box, Heading, Textarea, Button, Label, Spinner,
} from '@primitives';
import styled from 'styled-components';
import { FormattedMessage as M, injectIntl, IntlShape } from 'gatsby-plugin-intl';
import { rem } from 'polished';

// App imports
import { FeedbackFormRating } from './FeedbackFormRating';
import { postFeedback } from '../../services/api';
import { IFeedback } from '../../types/Feedback';
import { useStores } from '@stores';

interface IFeedbackForm {
  intl?: IntlShape;
}

const StyledTextarea = styled(Textarea)`
  height: ${rem('140px')};
  max-width: ${rem('800px')};
  width: calc(100% - 2rem);
`;

const TextareaContainer = styled.div`
  position: relative;
  max-width: ${rem('800px')};
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
`;

const Title = styled(Heading)`
  text-align: center;
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
`;

const SubmitButton = styled(Button)`
  position: absolute;
  width: 7rem;
  left: calc(50% - 3.5rem);
  bottom: -1rem;
  max-height: 45px;
`;

// @ts-ignore
const FeedbackForm: React.FC<IFeedbackForm> = injectIntl(({
  intl,
}) => {
  const { toastStore: { showSuccess, showError }, currentUserStore: { currentUser } } = useStores();
  const INITIAL_STATE = { rating: null, message: '' };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(INITIAL_STATE as IFeedback);

  const onSubmitSuccess = () => {
    setLoading(false);
    setFormData(INITIAL_STATE);
    showSuccess(intl.formatMessage({ id: 'common.footer.feedbackForm.messageSent' }));
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.rating || !formData.message) {
      setLoading(false);
      showError(intl.formatMessage({ id: 'common.footer.feedbackForm.requiredError' }));
      return;
    }
    const { first_name, last_name, phone_number, email } = currentUser;
    const userData = {
      firstName: first_name,
      lastName: last_name,
      phoneNumber: phone_number,
      email,
    };

    const msg = { ...formData, ...userData };
    postFeedback(msg, onSubmitSuccess);
  };

  const onRateClick = (id: IFeedback['rating']) => {
    setFormData({ ...formData, ...{ rating: id } });
  };

  const onMessageChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({ ...formData, ...{ message: e.currentTarget.value } });
  };

  return (
    <Box pt="3" pb="3" color="mainBlue">
      <Form onSubmit={onFormSubmit}>
        <Title mb="2" level="3" color="white">
          <M id="common.footer.feedbackForm.heading" />
        </Title>

        <FeedbackFormRating onRateClick={onRateClick} value={formData.rating} />

        <Label mb="0.5" htmlFor="free-text" color="white" fontWeight="300"><M id="common.footer.feedbackForm.freeText" /></Label>
        <TextareaContainer>
          <StyledTextarea
            onChange={onMessageChange}
            id="free-text"
            value={formData.message}
            placeholder={intl.formatMessage({ id: 'common.footer.feedbackForm.instruction' })}
          >
            {formData.message}
          </StyledTextarea>
          <SubmitButton
            type="submit"
            background="lightBlue"
            color="black"
            id="submit-feedback"
          >
            {loading && <Spinner color="mainBlue" />}
            {!loading && <M id="fields.label.send" />}
          </SubmitButton>
        </TextareaContainer>
      </Form>
    </Box>
  );
});

export { FeedbackForm };
