import React from 'react';
import { rem } from 'polished';
import { Button } from '@primitives';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Spinner } from '@primitives';

interface ITwoColorButton extends BasicStyles {
  title?: string;
  symbol: string;
  type?: string;
  onClick: Function;
  id: string;
  loading?: boolean;
}

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  margin-left: 0.5rem;
`;


const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-width: 180px;
  > .blue-box {
    font-size: 24px;;
    height: ${rem('45px')};
    width: ${rem('45px')};
    display: flex;
    background-color: ${({ theme }) => theme.colors.mainBlue};
    margin-right: 1rem;
    justify-content: center;
    align-items: center;
    margin-left: -1rem;
  }
`;

const TwoColorButton: React.FC<ITwoColorButton> = ({
  title, symbol, type, children, loading, ...rest
}) => (
    // @ts-ignore
    <StyledButton color="white" background="textBlue" pl="1" pr="1" type={type || 'button'} {...rest}>
      <span className="blue-box">
        <span>{symbol}</span>
      </span>
      {!loading && title && <M id={title} />}
      {loading && <StyledSpinner color="white" />}
      {children}
    </StyledButton>
  );

export { TwoColorButton };
