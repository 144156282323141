import React from 'react';
import styled from 'styled-components';
import { getBasicStyles } from '../../styles/theme-utils';

const StyledHr = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.cyan};
  ${(props) => getBasicStyles(props)};
  border: none;
`;

const Hr: React.FC<any> = ({ ...rest }) => (
  <StyledHr mb="3" mt="3" {...rest} />
);

export { Hr };
