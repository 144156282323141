import React from 'react';
import styled from 'styled-components';
import { Box } from '@primitives';
import { screens } from '../../styles/theme';

interface IShadowBlock {
  mb?: string;
  mt?: string;
  pl?: string;
  pr?: string;
  pb?: string;
  pt?: string;
  minHeight?: string;
  color?: Color;
}

const Container = styled(Box)`
  min-height: ${(props) => props.minHeight || '50px'};
  padding-left:${(props) => props.pl || '2.5rem'};
  padding-right:${(props) => props.pr || '2.5rem'};
  padding-top:${(props) => props.pt || '2rem'};
  padding-bottom:${(props) => props.pb || '2rem'};

  @media screen and (max-width: ${screens.sm}px) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const ShadowBlock: React.FC<IShadowBlock> = ({
  pt, pl, pr, pb, ...rest
}) => (
    <Container pt={pt} pl={pl} pr={pr} pb={pb} boxShadow {...rest} />
  );

export { ShadowBlock };
