import React, { createRef } from 'react';
import styled from 'styled-components';
import {
  Heading, InvisibleButton, Box
} from '@primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Message, MessageDate } from '.';
import { IThread, IMessage } from '../../types/Messages';
import { MessageForm } from './MessageForm';
import stripTags from 'striptags';
import { screens } from '../../styles/theme';

interface ThreadProps extends IThread {
  theme?: any;
  onClick: (id: string, status: IThread['status']) => void;
  open: boolean;
}

const getStatusColor = (props: any) => {
  const { status, theme: { colors } } = props;
  switch (status) {
    case 'unread':
      return colors.statusGreen;
    case 'unresponded':
      return colors.statusYellow;
    case 'read':
    default:
      return colors.white;
  }
};

const ThreadHeading = styled(Heading)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Button = styled(InvisibleButton)`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 2rem;
  @media screen and (max-width: ${screens.sm}px) {
    padding: 1rem;
  }
`;

const Container = styled.div<{ status: IThread['status'] }>`
  box-shadow: ${(props) => props.theme.boxShadow.basic};
  margin-bottom: 1rem;
  background-color: ${(props) => (props.status === 'read' ? props.theme.colors.contentGrey : props.theme.colors.white)};
`;

const TextContainer = styled.span`
  overflow: hidden;
  display: block;
  flex: 2 1 100%;
`;

const Status = styled.span<{ status: IThread['status'] }>`
  width: 150px;
  min-width: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: any) => getStatusColor(props)};
  padding: 0.5rem;
  margin-left: 1rem;
  font-size: 14px;
`;

const MessageListItem = styled.div`
  &:first-of-type > div {
    margin-top: 1.5rem;
    border-top: 1px solid ${(props) => props.theme.colors.cyan};
  }
  &:last-of-type > div {
    margin-bottom: 1.5rem;
  }
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 2rem;
  @media screen and (max-width: ${screens.sm}px) {
   margin: 1rem;
  }
`;

const MessageFormContainer = styled(Box)`
   padding: 0 2rem 2rem;

  @media screen and (max-width: ${screens.sm}px) {
   padding: 0 1rem 2rem 1rem;
  }
`;

const Thread: React.FC<ThreadProps> = ({
  subject, messages, status, last_message, onClick, thread_id, open,
}) => {
  const container = createRef<HTMLDivElement>();

  const delay = async (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

  const getStatus = () => {
    let translationId = '';
    switch (status) {
      case 'unread':
        translationId = 'unreadMessage';
        break;
      case 'unresponded':
        translationId = 'unansweredMessage';
        break;
      default:
      case 'read':
        translationId = 'messagesRead';
        break;
    }
    return <M id={`pages.messages.${translationId}`} />;
  };

  const onThreadClick = async () => {
    onClick(thread_id, status);

    if (container.current) {
      const immutable = Object.freeze({ ref: container.current });
      await delay(250);

      immutable.ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  return (
    <Container status={status} ref={container}>
      <Button onClick={onThreadClick} id={`thread-${thread_id}`}>
        <TextContainer>
          <ThreadHeading mb="0.25" level={status === 'read' ? '5' : '4'}>{subject}</ThreadHeading>
          <MessageDate thread date={last_message} />
        </TextContainer>
        <Status status={status}>{getStatus()}</Status>
      </Button>

      {open && (
        <>
          <Box>
            <StyledList>
              {messages && messages.map((msg: IMessage) => {
                return (
                  <MessageListItem key={msg.message_id}>
                    <Message {...msg} />
                  </MessageListItem>
                );
              })}
            </StyledList>
          </Box>
          <MessageFormContainer>
            <MessageForm threadId={thread_id} />
          </MessageFormContainer>
        </>
      )}
    </Container>
  );
};


export { Thread };
