import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import MessageIcon from '@icons/message.svg';

interface IMessageBadge {
  unreadCount?: number;
  size?: number;
}

const getDimensions = (props: any) => `${props.size || 40}px`;

const MessageIconContainer = styled.span<{ size?: number }>`
  background: ${(props) => props.theme.colors.mainBlue};
  width: ${(props) => getDimensions(props)};
  height: ${(props) => getDimensions(props)};
  border-radius: ${(props) => getDimensions(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const UnreadMessagesIndicator = styled.span`
  background: ${(props) => props.theme.colors.messageRed};
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
`;

const MessageBadge: React.FC<IMessageBadge> = ({ size, unreadCount }) => {
  return (
    <MessageIconContainer size={size}>
      {!!unreadCount && <UnreadMessagesIndicator />}
      <MessageIcon />
    </MessageIconContainer>
  );
};

export { MessageBadge };
