import React from 'react';
import { rem, transitions } from 'polished';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { getBasicStyles } from '../../styles/theme-utils';
import theme from '@styles';

const {
  colors: {
    white, mainBlue, disabledGrey, blackestBlack, placeholderGrey,
  },
} = theme;
interface IButton extends BasicStyles {
  background?: Color;
  color?: Color;
  type?: string;
  onClick?: Function;
  id?: string;
  title?: string;
  as?: string;
  disabled?: boolean;
}

const getHoverStyles = (background: Color) => {
  switch (background) {
    case 'white':
    case 'lightBlue':
      return white;
    case 'black':
      return blackestBlack;
    default:
      return mainBlue;
  }
};

const getFocusStyles = (background: Color) => {
  switch (background) {
    case 'white':
    case 'lightBlue':
      return white;
    case 'black':
      return blackestBlack;
    default:
      return mainBlue;
  }
};

const getDisabledStyles = (background: Color) => {
  switch (background) {
    case 'white':
    case 'lightBlue':
      return white;
    default:
      return disabledGrey;
  }
};
const StyledButton = styled.button<IButton>`
  ${(props) => getBasicStyles(props)};
  box-shadow: ${({ theme: { boxShadow } }) => boxShadow.basic};
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors[props.background || 'textBlue']};
  color: ${(props) => props.theme.colors[props.color || 'white']};
  font-weight: 300;
  font-size: ${(props) => props.theme.typography.fontSizes.xs};
  min-width: ${rem('115px')};
  min-height: ${rem('45px')};
  max-height: ${rem(45 * 2)};
  height: 100%;
  padding: 0 1rem;
  max-width: ${rem('250px')};
  cursor: pointer;
  box-sizing: border-box;
  ${transitions(['background-color'], '0.15s ease-in 0.0s')};

  &:disabled {
    cursor: default;
    color: ${(props) => (props.background === 'lightBlue' ? placeholderGrey : white)};
    background-color: ${(props) => getDisabledStyles(props.background as Color)};
    &:hover {
      background-color: ${(props) => getDisabledStyles(props.background as Color)};

    }
  }

  &:hover {
    background-color: ${(props) => getHoverStyles(props.background as Color)};
  }

  &:focus {
    background-color: ${(props) => getFocusStyles(props.background as Color)};
  }
`;

const Button: React.FC<IButton> = ({
  children, title, type, ...rest
}) => (
    // @ts-ignore
    <StyledButton type={type || 'button'} {...rest}>
      {title && <M id={title} />}
      {children}
    </StyledButton>
  );

export { Button };
