
// @ts-ignore
import ArrowDown from '@icons//arrow_down.svg';
// @ts-ignore
import HouseIcon from '@icons/house.svg';
import { InvisibleButton, ShadowBlock, Text } from '@primitives';
import { FormattedDate as Date, FormattedMessage as M } from 'gatsby-plugin-intl';
import snakeCase from 'lodash.snakecase';
import { observer } from 'mobx-react';
import { rem, transitions } from 'polished';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useStores } from '@stores';
import { until } from '../../styles/theme-utils';
import { IAgreement, ITheme } from '../../types';

interface IMenu {
  menuOpen: boolean;
  theme: ITheme;
}

interface IUserAgreements {
  agreements?: IAgreement[];
  id: string;
}

interface IAgreementItem {
  agreement: IAgreement;
}

interface IMenuButton {
  onClick: (agreement: IAgreement) => void;
  agreement: IAgreement;
  id: string;
}

const AgreementItemText = styled.span`
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  width: 70%;
`;

const Address = styled.span`
  font-size: ${rem('18px')};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Button = styled(InvisibleButton)`
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  font-weight: 300;
  height: ${rem('75px')};
  > svg {
    margin-right: 1.5rem;
  }

  ${until('md', `
    width: 100%;
    > svg { display: none };
  `)}
`;

const SelectedAgreementButton = styled(Button)`
  position: relative;

  .spinner {
    position: absolute;
    left: calc(50% - 0.75rem);
  }
  ${until('md', `
    color: #fff;
    width: 100%;
    &:focus {
      outline: none;
    }
    .contract-item-text-container {
      width: 100%;
      text-align: center;

      > span {
        width: 100%;
      }
    }
  `)}
`;

const ContractMenuToggleIcon = styled.span`
  background: ${(props) => props.theme.colors.mainBlue};
  height: ${rem('25px')};
  width: ${rem('25px')};
  border-radius: ${rem('25px')};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  margin-left: auto;
  transform: ${(props: IMenu) => (props.menuOpen && 'rotate(180deg) scaleX(-1)')};
  ${transitions('transform 0.3s ease-in 0s')};

  ${until('md', `
    border: 1px solid #fff;
    margin-left: auto;
    position: absolute;
    right: 2rem;
  `)};
`;

const ContractMenu = styled(ShadowBlock)`
  background: ${(props: IMenu) => props.theme.colors.white};
  position: absolute;
  width: calc(100% - 5rem);
  top: ${rem('85px')};
  overflow: hidden;
  height: ${(props: IMenu) => (props.menuOpen ? 'auto' : '0')};
  opacity: ${(props: IMenu) => (props.menuOpen ? '1' : '0')};
  ${transitions('opacity 0.25s ease-in 0s')};

  ${until('md', `
    left: 0;
    right: 0;
    position: absolute;
    z-index: 3;
    overflow: visible;
    background: #fff;
    top: 70px;
    width: 100%;
    display: flex;
  `)};
`;

const ContractMenuContainer = styled(ShadowBlock)`
  position: relative;
  ${until('md', `
    box-shadow: none;
    margin: 0;
    background: #0070BA;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 70px;

  `)}
`;

const ContractList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  li:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.colors.cyan};
  }
`;

const AgreementItem: React.FC<IAgreementItem> = ({ agreement }) => (
  <AgreementItemText className="agreement-item-text-container">
    <Address>{`${agreement.dwelling.address}`}</Address>
    <Text mt="0.25" fontSize="xs">
      <M id="common.header.hero.agreement" />
      <span> </span>
      <Date value={agreement.start_date} />
      {agreement.end_date && (
        <>
          <span> - </span>
          <Date value={agreement.end_date} />
        </>
      )}
    </Text>
  </AgreementItemText>
);

const MenuButton: React.FC<IMenuButton> = ({ agreement, onClick, id }) => {
  const onButtonClick = () => onClick(agreement);
  return (
    <Button id={id} onClick={onButtonClick}>
      <AgreementItem agreement={agreement} />
    </Button>
  );
};

const UserAgreements: React.FC<IUserAgreements> = observer(({ id }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const {
    agreementStore: {
      selectedAgreement, setSelectedAgreement, agreements,
    },
  } = useStores();

  const onMenuItemClick = async (agreement: IAgreement) => {
    setSelectedAgreement(agreement.agreement_id);
    setMenuOpen(!menuOpen);
  };

  const onSelectedAgreementClick = () => {
    setMenuOpen(!menuOpen);
  };

  if (!agreements || agreements.length < 2) return null;

  return (
    <ContractMenuContainer mb="2" pt="0" pr="0" pb="0" pl="0">
      <SelectedAgreementButton onClick={onSelectedAgreementClick} id={`selected-contract-${id}`}>
        <HouseIcon />
        {selectedAgreement && <AgreementItem agreement={selectedAgreement} />}
        {agreements.length > 1 && (
          <ContractMenuToggleIcon menuOpen={menuOpen}>
            <ArrowDown />
          </ContractMenuToggleIcon>
        )}
      </SelectedAgreementButton>

      {menuOpen && (
        <ContractMenu menuOpen={menuOpen}>
          <ContractList>
            {agreements
              .filter((c: IAgreement) => c.dwelling.address !== selectedAgreement.dwelling.address)
              .map((filteredAgreement: IAgreement) => {
                const buttonId = snakeCase(`change-contract-${filteredAgreement.dwelling.address}-${id}`);
                return (
                  <li key={filteredAgreement.dwelling.address}>
                    <MenuButton
                      id={buttonId}
                      agreement={filteredAgreement}
                      onClick={onMenuItemClick}
                    />
                  </li>
                );
              })}
          </ContractList>
        </ContractMenu>
      )}

    </ContractMenuContainer>
  );
});

export { UserAgreements };
