import React, { FC } from 'react';
import styled from 'styled-components';
import { rem, transitions } from 'polished';
import {
  injectIntl, InjectedIntl,
} from 'gatsby-plugin-intl';

const StyledSelect = styled.select`
  display: block;
  width: 100%;
  font-size: ${(props) => props.theme.typography.fontSizes.sm};
  height: ${rem('45px')};
  font-weight: 300;
  box-sizing: border-box;
  margin: 0;
  padding: 0 ${rem('25px')};
  box-shadow: ${(props) => props.theme.boxShadow.basic};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 2px;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  border: none;
  ${(props) => transitions(['box-shadow'], props.theme.transitions.input)};
  &::-ms-expand {
    display: none;
  }
  &:hover {
    box-shadow: ${(props) => props.theme.boxShadow.inputHover};
  }
  &:focus {
    border-color: #aaa;
    box-shadow: ${(props) => props.theme.boxShadow.inputFocus};
    outline: none;
    color: ${(props) => props.theme.colors.black};
    outline: none;
  }
  option {
    font-weight:normal;
  }
`;

interface ISelect {
  id: string;
  intl?: InjectedIntl;
  selectLabel?: string;
  options: Array<{
    label: string;
    id?: string;
  }>;
  onChange: Function;
  value?: string;
  skipTranslation?: boolean;
}

// @ts-ignore
const Select: FC<ISelect> = injectIntl(({
  id, selectLabel, options, onChange, value, intl, skipTranslation,
}: ISelect) => {
  const onValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e, e.target.value);
  };

  //const selectOptions = [...[{ id: undefined, label: 'select' }], ...options];
  return (
    <StyledSelect
      id={id}
      value={value}
      onChange={onValueChange}
      placeholder={selectLabel}
      aria-label={selectLabel}
    >
      <option></option>
      {options.map((option: any) => (
        <option key={option.id} value={option.id}>
          {skipTranslation && option.label}
          {!skipTranslation && intl && intl.formatMessage({ id: `fields.label.${option.label || option.id}` })}
        </option>
      ))}
    </StyledSelect>
  );
});

export { Select };
