import React, {
  FC, useState, ReactNode,
} from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button, Box } from '@primitives';
import { Color } from '../../types';
import { ToastStore } from '@stores';
// import theme from '../../styles/theme';

type ImageAlignment = 'column' | 'row' | undefined;
interface IFileUploader {
  label?: string;
  id: string;
  buttonLabel?: string | ReactNode;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  background?: Color;
  color?: Color;
  image?: boolean;
  imageAlignment?: ImageAlignment;
  imageUrl?: string;
  imageSize?: number;
  children?: ReactNode;
  disabled?: boolean;
}

const getImageSize = (imageSize?: number) => rem(imageSize || '45px');

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Label = styled.label<{ imageAlignment: ImageAlignment }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.imageAlignment || 'row'};

`;

interface PlaceholderProps {
  imageSize?: number;
  imageAlignment?: 'column' | 'row';
}
const Placeholder = styled.div<PlaceholderProps>`
  background: ${(props) => props.theme.colors.placeholderGrey};
  height: ${(props) => props.imageSize}px;
  width: ${(props) => props.imageSize}px;
  margin: ${(props) => (props.imageAlignment === 'column' ? '2rem 0 2rem 0' : '0 1rem 0 0')};
  border-radius: ${(props) => props.imageSize}px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: ${(props: IFileUploader) => getImageSize(props.imageSize)};
  height: ${(props: IFileUploader) => getImageSize(props.imageSize)};
  border-radius: ${(props: IFileUploader) => getImageSize(props.imageSize)};
  margin: ${(props: IFileUploader) => (props.imageAlignment === 'column' ? '2rem 0 2rem 0' : '0 1rem 0 0')};
  box-shadow: ${(props) => props.theme.boxShadow.basic};
`;

const FileUploader: FC<IFileUploader> = ({
  id, onChange, buttonLabel, onInputChange, color, background, imageUrl,
  imageSize, imageAlignment, image, children, disabled, ...rest
}) => {
  const [uploadedPic, setUploadedPic] = useState('');

  const getAcceptedFiletypes = () => {
    if (image) {
      return '.jpg,.png,.gif,jpeg';
    }
    return '';
  };
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files && e.currentTarget.files[0];
    if (!file) return;
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 10) {
      ToastStore.showError('Tiedosto on liian suuri');
      return;
    }
    onChange(e);
    if (onInputChange) onInputChange(e);
    if (image) setUploadedPic(URL.createObjectURL(file));
  };

  return (
    <Container {...rest}>
      <Label htmlFor={id} imageAlignment={imageAlignment} >
        {image && (
          <Box>
            {(!uploadedPic && !imageUrl) && <Placeholder imageSize={imageSize} imageAlignment={imageAlignment} />}
            {(uploadedPic || imageUrl) && (
              // @ts-ignore
              <Image imageAlignment={imageAlignment} imageSize={imageSize} src={uploadedPic || imageUrl} alt="Profiilikuva" />
            )}
          </Box>
        )}
        <Button
          id={`upload-file-${id}`}
          as="div"
          background={background}
          color={color}
          disabled={disabled}
        >
          {buttonLabel}
          {children}
        </Button>
        <HiddenInput id={id} onChange={onFileChange} type="file" accept={getAcceptedFiletypes()} />
      </Label>
    </Container>
  );
};

export { FileUploader };
