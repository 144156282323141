import theme from './';

const { screens } = theme;

type DisplayType = 'none' | 'block';

export const displayUntil = (screenSize: ScreenSize, as: DisplayType) => {
  const value = screens[screenSize];
  return `
  @media (max-width: ${value - 1}px) {
    display: ${as};
  }
  `;
};

export const displayFrom = (screenSize: ScreenSize, as: DisplayType) => {
  const value = screens[screenSize];
  return `
  @media (min-width: ${value - 1}px) {
    display: ${as};
  }
  `;
};

export const until = (screenSize: ScreenSize, style: string) => {
  const value = screens[screenSize];
  return `
    @media (max-width: ${value - 1}px) {
      ${style}
    }
  `;
};

export const from = (screenSize: ScreenSize, style: string) => {
  const value = screens[screenSize];
  return `
    @media (min-width: ${value}px) {
      ${style}
    }
  `;
};

export const getBasicStyles = (props: any) => {
  const getVal = (val?: string | number) => {
    if (!val) return '';
    return `${val}rem`;
  };

  let styles = `
    margin: ${getVal(props.m) || 0};
    padding: ${getVal(props.p) || 0};
    margin-bottom: ${getVal(props.mb)};
    margin-top: ${getVal(props.mt)};
    margin-right: ${getVal(props.mr)};
    margin-left: ${getVal(props.ml)};
    padding-bottom: ${getVal(props.pb)};
    padding-top: ${getVal(props.pt)};
    padding-right: ${getVal(props.pr)};
    padding-left: ${getVal(props.pl)};
  `;

  if (props.hideUntil) {
    styles += `${styles}
      ${displayUntil(props.hideUntil, 'none')};
    `;
  }

  if (props.onlyInDesktop) {
    styles += `${styles}
      ${displayUntil('md', 'none')};
    `;
  }

  if (props.onlyInMobile) {
    styles += `${styles}
      ${displayFrom('md', 'none')};
    `;
  }

  if (props.boxShadow) {
    styles += `${styles}
    box-shadow: ${theme.boxShadow.basic};
  `;
  }

  if (props.center) {
    styles += `${styles}
    text-align: center;
    `;
  }

  if (props.fontWeight) {
    styles += `${styles}
    font-weight: ${props.fontWeight};
    `;
  }

  if (props.underline) {
    styles += `${styles}
    text-decoration: underline;
    `;
  }
  return styles;
};

export default {
  displayUntil,
  until,
  displayFrom,
  getBasicStyles,
};
