import { PATHS } from './nav-items';

const FAULT_REPORT_FORM = {
  name: 'pages.myInformation.makeFaultReport',
  href: PATHS.forms.faultReport,
};

const CONTACTS = {
  name: 'pages.myInformation.customerService',
  href: PATHS.contacts
};

const SEND_MESSAGE_CUSTOMER_SERVICE = {
  name: 'pages.contactInformation.sendMessage',
  href: PATHS.messages.index,
};

export const PAGE_NAV_ITEMS = {
  dwelling: [
    {
      name: 'pages.myInformation.reserveParkingSpace',
      href: PATHS.forms.reserveParkingLot,
    },
    {
      name: 'pages.myInformation.orderMoreKeys',
      href: PATHS.forms.lostKeys,
    },
    {
      name: 'pages.myInformation.otherInfo',
      href: PATHS.living.index,
    },
  ],
  index: [
    {
      name: 'pages.myInformation.changePassword',
      href: PATHS.changePassword,
    },
    {
      name: 'pages.myInformation.changeEmail',
      href: PATHS.changeEmail,
    },
  ],
  residents: [
    {
      name: 'pages.myInformation.agreementInformation',
      href: PATHS.myInformation.agreement,
    },
    {
      name: 'pages.myInformation.orderMoreKeys',
      href: PATHS.forms.lostKeys,
    },
    {
      name: 'pages.myInformation.customerService',
      href: PATHS.contacts.customerService
    }
  ],
  faultReports: [
    {
      name: 'pages.myInformation.customerService',
      href: PATHS.contacts.customerService
    },
    FAULT_REPORT_FORM,
    {
      name: 'pages.myInformation.living',
      href: PATHS.living.index,
    },
  ],
  news: [
    {
      name: 'pages.living.living',
      href: PATHS.living.index,
    },
    {
      name: 'pages.living.customerBenefits',
      href: PATHS.living.customerBenefits,
    },
  ],
  agreement: [
    {
      name: 'pages.myInformation.myInformation',
      href: PATHS.myInformation.index,
    },
    {
      name: 'pages.myInformation.paymentInformation',
      href: PATHS.myInformation.paymentInformation,
    },
    {
      name: 'pages.myInformation.residents',
      href: PATHS.myInformation.residents,
    }, {
      name: 'pages.myInformation.agreementsPage.parkingLotsLinkText',
      href: PATHS.myInformation.dwelling,
    }
  ],
  faultReportForm: [{
    name: 'pages.faultReportForm.myFaultReports',
    href: PATHS.myInformation.faultReports,
  }, {
    name: 'pages.faultReportForm.maintenance',
    href: PATHS.contacts.maintenance,
  }],
  maintenance: [
    FAULT_REPORT_FORM,
    SEND_MESSAGE_CUSTOMER_SERVICE,
  ],
  rent: [
    SEND_MESSAGE_CUSTOMER_SERVICE
  ],
  messageInstructions: [
    FAULT_REPORT_FORM,
    {
      name: 'pages.myInformation.agreementsPage.terminateAgreement',
      href: PATHS.myInformation.agreement,
    }]
};
