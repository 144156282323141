import { RESIDENTS } from './my-information-fields';
import { isPhoneNumber } from '../components/Form/formValidators';

const faultReportBasic = [
  { id: 'title', label: 'faultReport.topic', fullWidth: false, required: true },
  { id: 'shared_space', label: 'faultReport.shared_space', fullWidth: false, type: 'customRender' },
  { id: 'descriptionText', type: 'customRender', fullWidth: true, },
  {
    id: 'description',
    hideLabel: true,
    type: 'textarea',
    fullWidth: true,
    required: true,
  },
  {
    id: 'attachments',
    hideLabel: true,
    type: 'customRender',
  },
  { id: 'informerHeading', type: 'customRender', fullWidth: true },
  { id: 'number', label: 'dwellingNo', type: 'readOnly' },
  { id: 'first_name', label: 'firstName', type: 'readOnly' },
  { id: 'phone_number', label: 'phoneNumber', required: true, validators: [isPhoneNumber] },
  { id: 'email', type: 'readOnly' },
  { id: 'last_name', label: 'lastName', type: 'readOnly' },
];

export const LOST_KEYS = faultReportBasic.map((elem) => {
  if (elem.id === 'descriptionText' || elem.id === 'title') return null;
  if (elem.id === 'description') return {
    ...elem,
    ...{ hideLabel: false, label: 'message' },
  }
  return elem;
}).filter((e) => e);

export const RESERVE_PARKING_LOT = LOST_KEYS;

export const FAULT_REPORT = [
  ...faultReportBasic,
  ...[{
    id: 'dwellingAccessHeading',
    type: 'customRender',
    fullWidth: true,
  }, {
    id: 'entry',
    label: 'faultReport.dwellingAccess',
    type: 'customRender',
  }, {
    id: 'pets',
    label: 'faultReport.dwellingPets',
    type: 'customRender'
  }],
];

export const MOVE_IN_REPORT = {
  general: [
    { id: 'dwelling.address', label: 'address', type: 'readOnly' },
    { id: 'moveInDate', type: 'readOnly', parser: 'date' },
  ],
  residents: [
    { id: 'first_name', label: 'firstNames', type: 'readOnly' },
    { id: 'last_name', label: 'lastName', type: 'readOnly' },
  ],
  doorNames: [
    { id: 'door_name1', label: 'moveInReport.lastName1' },
    { id: 'door_name2', label: 'moveInReport.lastName2' },
  ],
  newResident: RESIDENTS.newResident,
};
