import React from 'react';
import { Link as GatsbyLink } from 'gatsby-plugin-intl';
import styled from 'styled-components';
// @ts-ignore
import Arrow from '../../resources/icons/link_arrow.svg';
import { getBasicStyles } from '../../styles/theme-utils';

export interface NavLinkProps extends BasicStyles {
  color?: Color;
  to?: string;
  href?: string;
  active?: boolean;
  arrow?: boolean;
  fontWeight?: FontWeight;
  underline?: boolean;
  onClick?: any;
  fontSize?: FontSize;
  inline?: boolean;
}

const getStyles = (props: NavLinkProps) => (`
  font-weight: inherit;
  display: ${props.inline ? 'inline' : 'flex'};
  align-items: center;
  ${getBasicStyles(props)};
  color: ${(props.color ? props.theme.colors[props.color] : props.theme.colors.textBlue)};
  font-weight: ${props.fontWeight || '300'};
  font-size: ${props.theme.typography.fontSizes[props.fontSize as string] || props.theme.typography.fontSizes.sm};
  text-decoration: ${props.underline ? 'underline' : 'none'};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`);

const StyledGatsbyLink = styled(GatsbyLink)`${(props) => getStyles(props)} `;
const StyledLink = styled.a`${(props) => getStyles(props)} `;

const Link: React.FC<NavLinkProps> = ({
  children, arrow, to, fontSize, onClick, ...rest
}) => {
  const onLinkClick = (e: Event) => {
    if (onClick) onClick(e);
  };

  if (to) {
    return (
      <StyledGatsbyLink onClick={onLinkClick} to={to} {...rest}>
        {children}
        {arrow && <Arrow />}
      </StyledGatsbyLink>
    );
  }
  // @ts-ignore
  return <StyledLink onClick={onLinkClick} fontSize={fontSize} {...rest}>{children}</StyledLink>;
};

export { Link };
