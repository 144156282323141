import React from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { getMessageAttachment } from '../../services/api';
import {
  InvisibleButton, Text, Box, Link
} from '@primitives';
import FileSaver from 'file-saver';

const AttachmentContainer = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin: 0 0.5rem;
  }
`;

const Links = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a {
    white-space: nowrap;
  }
  > a:not(:last-of-type):after {
    content: ',';
    margin-right: 5px;
  }
`;

interface IAttachment {
  attachment: IMessageAttachment;
  onAttachmentRemove?: (name: File['name']) => void;
}

interface IMessageAttachments {
  attachments: IMessageAttachment[];
  onAttachmentRemove?: IAttachment['onAttachmentRemove'];
}

const Attachment: React.FC<IAttachment> = ({ attachment, onAttachmentRemove }) => {
  const onRemoveClick = () => {
    if (onAttachmentRemove) onAttachmentRemove(attachment.name);
  };

  return (
    <AttachmentContainer>
      <Text fontSize="xs">{attachment.name}</Text>
      <InvisibleButton onClick={onRemoveClick} id={`remove-${attachment.name}`}>
        <IoMdCloseCircle />
      </InvisibleButton>
    </AttachmentContainer>
  );
};

const MessageAttachments: React.FC<IMessageAttachments> = ({ attachments, onAttachmentRemove }) => {
  if (!attachments || !attachments.length) return null;

  const onClick = async ({ download_id, name }: IMessageAttachment) => {
    const response = await getMessageAttachment(download_id);
    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, name);
  };

  /**
   * Truncates long filenames without white space
   */
  const getTruncatedName = (name: string) => {
    if (name.indexOf(' ') >= 0) return name;
    if (name.length > 20) {
      const start = name.substring(0, 5);
      const end = name.substring(name.length - 8, name.length);
      return `${start}...${end}`;
    }
    return name;
  };

  return (
    <Box mt="0.5" mb="0.5" style={{ display: 'flex' }}>
      <Text uppercase fontSize="xs" fontWeight="400" mr="1" style={{ minWidth: '80px' }}>
        <M id="pages.messages.attachments" values={{ count: attachments.length }} />
      </Text>
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        {onAttachmentRemove && attachments.map((attachment) => (
          <Attachment
            onAttachmentRemove={onAttachmentRemove}
            key={uuid()}
            attachment={attachment}
          />
        ))}

        {!onAttachmentRemove && (
          <Links>
            {attachments.map((a) => <Link onClick={() => onClick(a)} fontSize="xs">{getTruncatedName(a.name)}</Link>)}
          </Links>
        )}
      </Box>
    </Box>
  );
};

export { MessageAttachments };
