import validatorIsEmail from 'validator/lib/isEmail';
import validatorIsMobilePhone from 'validator/lib/isMobilePhone';

import { FinnishSSN } from 'finnish-ssn'
export const required = (value: string) => (value ? undefined : 'fields.validation.required');

export const isEmail = (val: string) => (val && validatorIsEmail(val) ? undefined : 'fields.validation.invalidEmail');

export const isValidSSN = (val: string) => {
  if (val === undefined) return undefined;
  return !FinnishSSN.validate(val) ? 'fields.validation.invalidSSN' : undefined;
}

export const isPhoneNumber = (val: string) => (val && validatorIsMobilePhone(val)) ? undefined : 'fields.validation.invalidPhoneNumber';
