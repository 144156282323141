import React from 'react';
import styled from 'styled-components';
import { Box } from '@primitives';
import { MessageDate } from './MessageDate';
import { IMessage } from '../../types/Messages';
import { MessageAttachments } from './MessageAttachments';
import { useStores } from '@stores';
import { ICurrentUserStore } from '../../types/CurrentUser';
import { ProfilePicture } from '@components/ProfilePicture';

const Container = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.greyBorder};
  display: flex;
`;


const Message: React.FC<IMessage> = ({
  content, created, attachments, direction,
}) => {
  const {
    currentUserStore: { profilePicUrl }
  }: {
    currentUserStore: ICurrentUserStore
  } = useStores();

  return (
    <Container>
      <Box mr="1">
        {direction === 'from_customer' && <ProfilePicture imageUrl={profilePicUrl} size={50} />}
        {direction === 'to_customer' && <ProfilePicture size={50} />}
      </Box>
      <div>
        <Box mb="0.5">
          <MessageDate date={created} />
        </Box>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <MessageAttachments attachments={attachments} />
      </div>
    </Container>
  );
};

export { Message };
