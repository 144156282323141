import React from 'react';
import { InvisibleButton, Heading, Text, Link } from '@primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { rem } from 'polished';
import { INavItem } from '../../../types';

const MainLevelHeading = styled(Heading)`
  min-height: ${rem('78px')};
  padding: 0 2rem;
  display: flex;
  align-items: center;

  span {
    margin-left: auto;
  }
`;

const Button = styled(InvisibleButton)`
  width: 100%;
  h3 {
    justify-content: space-between;
  }
`;

interface Props {
  onToggleClick: (item: INavItem) => void;
  item: INavItem;
  isOpen: boolean;
}

const MenuItemToggle: React.FC<Props> = ({
  onToggleClick, item, isOpen,
}) => {
  const renderIcon = () => {
    if (!item.subPages) return null;
    return isOpen ? '-' : '+';
  };

  const onClick = () => {
    onToggleClick(item);
  };

  const getContent = () => (
    <MainLevelHeading level="3">
      <M id={item.name} />
      <Text fontSize="lg">{renderIcon()}</Text>
    </MainLevelHeading>
  );

  if (item.subPages) return <Button id={`toggle-${item.href}`} onClick={onClick}>{getContent()}</Button>;
  return <Link onClick={onClick} to={item.href}>{getContent()}</Link>;
};

export { MenuItemToggle };
