import React from 'react';
import { rem, transitions } from 'polished';
import styled from 'styled-components';
import { getBasicStyles } from '../../styles/theme-utils';
import theme from '@styles';

const {
  typography, boxShadow, transitions: t,
} = theme;

const StyledTextarea = styled.textarea`
  ${(props: any) => getBasicStyles(props)};
  box-shadow: ${boxShadow.basic};
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  border-radius: ${rem('2px')};
  border: none;
  font-weight: 300;
  font-size: ${typography.fontSizes.sm};
  height: 200px;
  width: calc(100% - 2rem);
  padding: 1rem;
  resize: none;
  outline: none;
  &::placeholder {
    font-style: italic;
    text-align: center;
  }
  &:hover {
    box-shadow: ${boxShadow.inputHover};
  }
  &:focus {
    box-shadow: ${boxShadow.inputFocus};
  }
  ${transitions(['box-shadow'], t.input)};
`;

const Textarea: React.FC<any> = ({ children, ...rest }) => (
  <StyledTextarea {...rest}>{children}</StyledTextarea>
);

export { Textarea };
