import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { rem } from 'polished';
import Img from 'gatsby-background-image';
import { observer } from 'mobx-react';
import { Heading, Link, Text, Box } from '@primitives';
import { UserAgreements } from '.';
import { until } from '../../styles/theme-utils';
import { useStores } from '@stores';
import { MessageBadge } from '@components/Messages';
import { ProfilePicture } from '@components/ProfilePicture';
import { IconShortcutList } from '@components/IconShortcutList';
import { PATHS } from '../../constants/nav-items';

interface IHero {
  heroImage: IHeroImage;
  heroContent?: ReactNode;
  extraShortcuts?: any;
}

const HeroContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-row-gap: 0;
  grid-column-gap: 0;
  ${until('xl', `
    grid-template-columns: 50% 50%;
  `)}
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: ${rem('230px')};
  position: relative;
  > div {
    position: absolute;
    height: 100%;
  }
`;

const MessageBadgeContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  justify-content: flex-end;
  ${until('lg', `margin-left: 0; justify-content: flex-start;`)};

`;

const Columns = styled(Box)`
  display: flex;
  ${until('lg', `display: block`)};
  > * {
    width: 50%;
  }
`;

const UserContainer = styled(Box)`
  position: relative;
`;

const ProfilePicContainer = styled.div`
  left: -${rem('115px')};
  top: 1.5rem;
  position: absolute;
`;

const Logo = styled.img`
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 2rem;
`;

const Hero: React.FC<IHero> = observer(({
  heroImage, heroContent, extraShortcuts,
}) => {
  const {
    agreementStore: { agreements },
    currentUserStore: { currentUser, profilePicUrl },
    messageStore: { unreadCount },
  }: {
    agreementStore: IAgreementStore;
    currentUserStore: ICurrentUserStore;
    messageStore: IMessageStore;
  } = useStores();

  return (
    <HeroContainer>
      <ImageContainer>
        {heroImage && (
          <Img
            fluid={heroImage.childImageSharp.fluid}
            // @ts-ignore
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          />
        )}
        <Link to="/">
          <Logo src="/logo.svg" />
        </Link>
      </ImageContainer>
      <Box p="3" color="lightBlue">
        <UserContainer pt="3" >
          <Columns ml="3" mb={profilePicUrl ? '2' : '0'}>
            <Box>
              {profilePicUrl && (
                <ProfilePicContainer>
                  <ProfilePicture imageUrl={profilePicUrl} size={115} />
                </ProfilePicContainer>
              )}
              {currentUser && (
                <Heading level="3" mb="1">
                  <M id="common.greeting" values={{ name: currentUser.first_name }} />
                </Heading>
              )}
              <Link to={PATHS.auth.logout} mb="1" arrow>
                <Text><M id="common.header.hero.logout" /></Text>
              </Link>
            </Box>
            <MessageBadgeContainer>
              <Text mr="1" color="mainBlue" uppercase fontSize="sm"><M id="common.messages" /></Text>
              <Link to={PATHS.messages.index}><MessageBadge size={50} unreadCount={unreadCount} /></Link>
            </MessageBadgeContainer>
          </Columns>
          <UserAgreements id="desktop-agreements-menu" agreements={agreements} />
        </UserContainer>
        <IconShortcutList extraShortcuts={extraShortcuts} />
        {heroContent}
      </Box>
    </HeroContainer>
  );
});

export { Hero };
