import React from 'react';
import { Box, Link } from '@components/Primitives';
import { FeedbackForm } from '.';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';
import { PATHS } from '../../constants/nav-items';

interface IFooter {
}

const WhiteBar = styled(Box)`
  display: flex;
  justify-content: center;
`;

const Footer: React.FC<IFooter> = () => {
  const { formatMessage } = useIntl();
  const items = [{
    id: 1,
    label: formatMessage({ id: 'common.privacyPolicy' }),
    to: PATHS.privacyPolicy,
  }, {
    id: 2,
    label: 'yhkodit.fi',
    href: 'http://www.yhkodit.fi',
  }];

  return (
    <footer>
      <Box mt="3">
        <FeedbackForm />
      </Box>
      <WhiteBar p="1">
        {items.map((item) => (<Link mr="1" to={item.to} href={item.href} key={item.id}>{item.label}</Link>))}
      </WhiteBar>
    </footer>
  );
};

export { Footer };
