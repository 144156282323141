import React from 'react';
import { Link as GatsbyLink } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { rem } from 'polished';
// @ts-ignore
import { getBasicStyles } from '../../styles/theme-utils';

export interface NavLinkProps extends BasicStyles {
  color?: Color;
  to?: string;
  href?: string;
  active?: boolean;
  target?: string;
  id?: string;
}

const getStyles = (props: { theme: ITheme }) => `
  height: ${rem('45px')};
  border-radius: 2px;
  background: ${props.theme.colors.mainBlue};
  color: ${props.theme.colors.white} !important;
  ${getBasicStyles(props)};
  font-size: ${props.theme.typography.fontSizes.xs};
  text-decoration: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: ${props.theme.boxShadow.basic};
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  ${(props) => getStyles(props)};
`;

const StyledLink = styled.a`
  ${(props) => getStyles(props)};
`;

const LinkButton: React.FC<NavLinkProps> = ({
  href, to, children, ...rest
}) => {
  if (to) {
    return (
      <StyledGatsbyLink to={to} {...rest}>
        {children}
      </StyledGatsbyLink>
    );
  }
  return (
    // @ts-ignore
    <StyledLink
      href={href}
      pl="1"
      pr="1"
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

export { LinkButton };
