import React from 'react';
import { rem } from 'polished';
import moment from 'moment';
import { Layout } from '@components/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { ResidentChecklist } from '@components/ResidentChecklist';
import { useStores } from '../../../stores/index';
import { observer } from 'mobx-react';

interface IndexPageLayout {
  extraShortcuts: any;
}

const IndexPageLayout: React.FC<IndexPageLayout> = observer(({
  children, extraShortcuts,
}) => {
  const {
    agreementStore: { selectedAgreement },
  }: {
    agreementStore: IAgreementStore;
    authStore: IAuthStore;
  } = useStores();
  const { file } = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "index_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 936) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);

  const renderAfterHeader = () => {
    const startDate = selectedAgreement?.occupancy_start_date || selectedAgreement?.start_date;
    if (selectedAgreement && moment().subtract(2, 'week').isSameOrBefore(startDate)) return <ResidentChecklist />;
    return null;
  };

  return (
    <Layout maxWidth={rem('1220px')} heroImage={file} extraShortcuts={extraShortcuts} afterHeader={renderAfterHeader()}>
      {children}
    </Layout>
  );
});

export { IndexPageLayout };
