/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { Toast } from '.';
import { useStores } from '@stores';
import { IToast } from '../../types';
import { observer } from 'mobx-react';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {

}

const ToastProvider: FC<Props> = observer(() => {
  const { toastStore: { toasts, closeToast, closeToasts } } = useStores();
  const { formatMessage } = useIntl();

  useEffect(() => {
    return () => {
      closeToasts();
    };
  }, []);

  const toastArr = toasts.toJS();

  const onCloseToastClick = (index: number) => {
    closeToast(index);
  };


  return (
    <div>
      {toastArr.map((toast: IToast, index: number) => {
        const msg = toast.translate ? formatMessage({ id: toast.msg }) : toast.msg;

        return (
          <Toast
            id={toast.id}
            onCloseClick={onCloseToastClick}
            key={toast.id}
            index={index}
            type={toast.type}
            msg={msg}
          />
        );
      })}
    </div>
  );
});

export { ToastProvider };
