import { PATHS } from './nav-items';
export const SHORTCUT_ITEMS = [{
  name: 'common.content.shortcutBox.makeFaultReport',
  href: PATHS.forms.faultReport,
}, {
  name: 'common.content.shortcutBox.reserveParkingSpace',
  href: PATHS.forms.reserveParkingLot,
}, {
  name: 'common.content.shortcutBox.orderKeys',
  href: PATHS.forms.lostKeys,
}, {
  name: 'common.content.shortcutBox.terminateContract',
  href: PATHS.myInformation.agreement,
}, {
  name: 'common.content.shortcutBox.changeResidentInformation',
  href: PATHS.myInformation.residents,
}, {
  name: 'common.content.shortcutBox.saunaInfo',
  href: PATHS.living.sauna,
}];
