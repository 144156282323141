
import React from 'react';
import styled from 'styled-components';
import { Color, BasicStyles } from '../../types';
import { getBasicStyles } from '../../styles/theme-utils';

interface ISpinner extends BasicStyles {
  color?: Color;
  className? : string;
}

const getColor = (props: ISpinner) => {
  return (props.color ? props.theme.colors[props.color] : props.theme.colors.white);
};

const StyledSpinner = styled.div`
  ${(props: ISpinner) => getBasicStyles(props)};
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  left: calc(50% - 1.5rem);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    border: 2px solid ${(props) => getColor(props)};
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color:${(props) => getColor(props)} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner: React.FC<ISpinner> = ({ color, ...rest }) => (
  <StyledSpinner className="spinner" color={color} {...rest}>
    <div />
    <div />
    <div />
    <div />
  </StyledSpinner>
);

export { Spinner };
