import React, { useState } from 'react';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import {
  Textarea, Label, Box, InvisibleButton, Button, Text, Input, Spinner,
} from '@primitives';
import styled from 'styled-components';
// @ts-ignore
import AttachmentIcon from '@icons/attachment.svg';
import { FileUploader } from '@components/FileUploader';
import { MessageAttachments } from './MessageAttachments';
import { useStores } from '@stores';
import { ProfilePicture } from '@components/ProfilePicture';
import { sendMessageAttachment } from '../../services/api';
import { until } from '../../styles';
import ToastStore from '../../stores/ToastStore';
import { observer } from 'mobx-react';
import { NaviButtons } from '@components/NaviButtons';
import { PAGE_NAV_ITEMS } from '../../constants/page-nav-items';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Controls = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

const Subject = styled(Input)`
  width: calc(100% - 3rem);
`;

const FormContainer = styled(Box)`
  margin-left: 1rem;
  width: 100%;
  ${until('md', `
    margin-left: 0rem;
  `)}
`;

const Pic = styled(ProfilePicture)`
  ${until('md', `
    display: none;
  `)}
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: calc(50% - 12px);
`;

const AttachmentUploader = styled(FileUploader)`
  width: auto;
  margin-right: 1rem;
  #upload-file-attachments {
    border: 2px solid ${(props) => props.theme.colors.textBlue};
  }
`;
interface IMessageForm {
  threadId?: string;
  instructions?: string;
  onCancel?: () => void;
}

const MessageForm: React.FC<IMessageForm> = observer(({
  threadId, onCancel, instructions,
}) => {
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const {
    currentUserStore: { profilePicUrl },
    messageStore: { postMessage, postThreadMessage, sending }
  }: {
    messageStore: IMessageStore;
    currentUserStore: ICurrentUserStore
  } = useStores();

  const [attachments, setAttachments] = useState<IMessageAttachment[] | []>([]);
  const [sendingAttachment, setSendingAttachment] = useState(false);

  const onReset = () => {
    setMessage('');
    setAttachments([]);
    if (onCancel) onCancel();
  };

  const onTextareaChange = (e: React.FormEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) return;
    const file = e.currentTarget.files[0];
    setSendingAttachment(true);
    try {
      const results = await sendMessageAttachment(file);
      const newAttachments = [...[], ...attachments];
      newAttachments.push({ download_id: results.data.download_id, name: file.name, filetype: file.type });
      setSendingAttachment(false);
      setAttachments(newAttachments);
    } catch {
      ToastStore.showError('errors.sendAttachment', true);
      setSendingAttachment(false);
    }
  };

  const onAttachmentRemove = (name: File['name']) => {
    const newAttachments = attachments.filter((a) => a.name !== name);
    setAttachments(newAttachments);
  };

  const onChangeTopic = (e: React.FormEvent<HTMLSelectElement>) => {
    setSubject(e.currentTarget.value);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const msg = {
      content: message,
      files: attachments,
    }

    try {
      // @ts-ignore
      if (threadId) {
        await postThreadMessage(threadId, msg);
      } else {
        await postMessage(msg, subject);
      }
      onReset();
    } catch (error) {
      console.error(error);
    }
  };

  const renderLabel = (id: string, translationId: string) => (
    <Label mb="0.5" fontSize="xs" fontWeight="300" htmlFor={id}>
      <M id={`pages.messages.${translationId}`} />
    </Label>
  );

  return (
    <Box>
      {instructions && (
        <Box mb="3">
          <Text mb="2">{instructions}</Text>
          <NaviButtons items={PAGE_NAV_ITEMS.messageInstructions} />
        </Box>
      )}
      <Container>
        <Pic imageUrl={profilePicUrl} size={50} />
        <FormContainer>
          <form onSubmit={onSubmit}>
            {threadId && renderLabel('message', 'replyToThread')}
            <Box mb="1">
              {!threadId && (
                <>
                  {renderLabel('message-topic', 'messageTopic')}
                  <Subject
                    onChange={onChangeTopic}
                    id="subject"
                    value={subject}
                  />
                </>
              )}
            </Box>

            {!threadId && renderLabel('message', 'writeMessage')}
            <Box style={{ position: 'relative' }}>
              {(sending || sendingAttachment) && <StyledSpinner color="mainBlue" />}
              <Textarea onChange={onTextareaChange} id="message" value={message} />
              {!!attachments.length && (
                <MessageAttachments attachments={attachments as any} onAttachmentRemove={onAttachmentRemove} />
              )}
            </Box>
            <Controls mt="1">
              <InvisibleButton onClick={onReset} id="cancel-message" mr="1">
                <M id="fields.label.cancel" />
              </InvisibleButton>
              <AttachmentUploader
                color="textBlue"
                background="white"
                onChange={onFileChange}
                disabled={sending || sendingAttachment}
                id="attachments">
                <AttachmentIcon />
                <Text ml="0.5">
                  <M id="pages.messages.addAttachment" />
                </Text>
              </AttachmentUploader>
              <Button id="send-message" type="submit" disabled={sending || sendingAttachment}><M id="fields.label.send" /></Button>
            </Controls>
          </form>
        </FormContainer>
      </Container>
    </Box>
  );
});

export { MessageForm };
